// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .mat-select-panel {
  /* background: #c32828; */
  margin-top: 38px !important;
}
::ng-deep .mat-select-panel .name-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  display: inline-block;
}

:host ::ng-deep .setting-type-dropdown .mdc-text-field {
  padding: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNldHRpbmdzLWhlYWRlci5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLHlCQUFBO0VBRUEsMkJBQUE7QUFBSjtBQUVJO0VBQ0ksbUJBQUE7RUFDQSxnQkFBQTtFQUNBLHVCQUFBO0VBQ0EsWUFBQTtFQUNBLHFCQUFBO0FBQVI7O0FBS0k7RUFDSSxxQkFBQTtBQUZSIiwiZmlsZSI6InNldHRpbmdzLWhlYWRlci5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIjo6bmctZGVlcCAubWF0LXNlbGVjdC1wYW5lbCB7XG4gICAgLyogYmFja2dyb3VuZDogI2MzMjgyODsgKi9cbiAgICAvLyBtYXJnaW4tdG9wOiA1Mi41cHggIWltcG9ydGFudDtcbiAgICBtYXJnaW4tdG9wOiAzOHB4ICFpbXBvcnRhbnQ7XG5cbiAgICAubmFtZS10ZXh0IHsgXG4gICAgICAgIHdoaXRlLXNwYWNlOiBub3dyYXA7XG4gICAgICAgIG92ZXJmbG93OiBoaWRkZW47XG4gICAgICAgIHRleHQtb3ZlcmZsb3c6IGVsbGlwc2lzO1xuICAgICAgICB3aWR0aDogMjAwcHg7XG4gICAgICAgIGRpc3BsYXk6IGlubGluZS1ibG9jaztcbiAgICB9XG59XG5cbjpob3N0IDo6bmctZGVlcCB7XG4gICAgLnNldHRpbmctdHlwZS1kcm9wZG93biAubWRjLXRleHQtZmllbGQge1xuICAgICAgICBwYWRkaW5nOiAwICFpbXBvcnRhbnQ7XG4gICAgfVxufSJdfQ== */`, "",{"version":3,"sources":["webpack://./src/core/components/settings-header/settings-header.component.scss"],"names":[],"mappings":"AAAA;EACI,yBAAA;EAEA,2BAAA;AAAJ;AAEI;EACI,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,YAAA;EACA,qBAAA;AAAR;;AAKI;EACI,qBAAA;AAFR;AACA,o8BAAo8B","sourcesContent":["::ng-deep .mat-select-panel {\n    /* background: #c32828; */\n    // margin-top: 52.5px !important;\n    margin-top: 38px !important;\n\n    .name-text { \n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        width: 200px;\n        display: inline-block;\n    }\n}\n\n:host ::ng-deep {\n    .setting-type-dropdown .mdc-text-field {\n        padding: 0 !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
