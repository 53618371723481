import { Component, OnInit } from '@angular/core';
import {TilesService} from '../../core/services/word-filter-tiles.service';
import {switchMap, take} from 'rxjs/operators';
import {SettingsService} from '../../core/services/new-settings.service';
import {UserService} from '../../core/services/user.service';

@Component({
  selector: 'app-settings-basic',
  templateUrl: './html/basic.html',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})
export class LetterTilesComponent implements OnInit {
  userSettings: any;
  tiles: any[] = [];
  dropdownSettings: any[] = [
    {
      sectionName: 'General',
      dropdowns: [
        {
          label: 'Flashcard Tile View Color',
          model: 'flashcardstyle',
          values: [
            {value: true, display: 'On'},
            {value: false, display: 'On'}
          ]
        },
        {
          label: 'Default Text Size',
          model: 'defaulttextsize',
          values: [
            {value: '10', display: '10px'},
            {value: '18', display: '18px'},
            {value: '26', display: '26px'},
            {value: '34', display: '34px'},
            {value: '42', display: '42px'},
            {value: '50', display: '50px'},
          ]
        },
      ]
    },
    {
      sectionName: 'Tileboard Tiles',
      dropdowns: [
        {
          label: 'Tile Font',
          model: 'tilefont',
          values: [
            {value: 'ff-arial', display: 'Arial'},
            {value: 'ff-century', display: 'Century Gothic'},
            {value: 'ff-helvetica', display: 'Helvetica'},
            {value: 'ff-opendyslexic', display: 'Open Dyslexic'}
          ]
        },
        {
          label: 'Background Color',
          model: 'bgcolor',
          values: [
            {value: 'bg-default', display: 'Default'},
            {value: 'bg-black', display: 'Black'},
            {value: 'bg-white', display: 'White'},
            {value: 'bg-blue', display: 'Blue'},
            {value: 'bg-green', display: 'Green'},
            {value: 'bg-yellow', display: 'Yellow'},
            {value: 'bg-cream', display: 'Cream'},
            {value: 'bg-pink', display: 'Pink'},
          ]
        },
        {
          label: 'Tile Halo Color',
          model: 'tilehighlightcolor',
          values: [
            {value: 'thc-blue', display: 'Blue'},
            {value: 'thc-red', display: 'Red'},
            {value: 'thc-yellow', display: 'Yellow'},
            {value: 'thc-green', display: 'Green'},
            {value: 'thc-purple', display: 'Purple'},
            {value: 'thc-orange', display: 'Orange'},
            {value: 'thc-pink', display: 'Pink'},
          ]
        },
        {
          label: 'Freeze Tileboard Rows',
          model: 'freezerows',
          values: [
            {value: '0', display: 'None'},
            {value: '1', display: 'Top 1'},
            {value: '2', display: 'Top 2'},
            {value: '3', display: 'Top 3'},
            {value: '4', display: 'Top 4'},
          ]
        },
      ]
    }
  ];
  tileDropdownSettings: any[] = [
    {
      label: 'Tile Color',
      model: 'tilebgcolor',
      values: [
        {value: 'c-red', display: 'Red'},
        {value: 'c-peach', display: 'Peach'},
        {value: 'c-yellow', display: 'Yellow'},
        {value: 'c-lightyellow', display: 'Light Yellow'},
        {value: 'c-green', display: 'Green'},
        {value: 'c-lightgreen', display: 'Light Green'},
        {value: 'c-blue', display: 'blue'},
        {value: 'c-lightblue', display: 'Light Blue'},
        {value: 'c-purple', display: 'Purple'},
        {value: 'c-lightpurple', display: 'Light Purple'},
        {value: 'c-orange', display: 'Orange'},
        {value: 'c-gray', display: 'Gray'},
        {value: 'c-pink', display: 'Pink'},
        {value: 'c-cream', display: 'Cream'},
        {value: 'c-transparent', display: 'Transparent'},
      ]
    },
    {
      label: 'Tile Font Color',
      model: 'tilefontcolor',
      values: [
        {value: 'fc-black', display: 'Black'},
        {value: 'fc-white', display: 'White'},
        {value: 'fc-red', display: 'Red'},
      ]
    },
  ];

  constructor(
    private settingsService: SettingsService,
    private tilesService: TilesService,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.tilesService
      .getAllTiles()
      .pipe(
        take(1),
        switchMap((tiles: any[]) => {
          this.tiles = tiles;

          return this.settingsService.get(JSON.parse(localStorage.profile).user_metadata.uid);
        })
      )
      .subscribe((userSettings: any) => {
        this.userSettings = userSettings;
      });
  }

  update() {
    this.settingsService
      .update(this.userSettings, this.userSettings._id)
      .pipe(
        take(1)
      )
      .subscribe(() => {
        //
      });
  }
}
