// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#email .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInBhc3N3b3JkcmVzZXQuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQ0k7RUFDSSw0QkFBQTtBQUFSIiwiZmlsZSI6InBhc3N3b3JkcmVzZXQuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIjZW1haWx7XG4gICAgLm1hdC1mb3JtLWZpZWxkLXdyYXBwZXIge1xuICAgICAgICBwYWRkaW5nLWJvdHRvbTogMCAhaW1wb3J0YW50O1xuICAgIH1cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/passwordreset/passwordreset.component.scss"],"names":[],"mappings":"AACI;EACI,4BAAA;AAAR;AACA,oYAAoY","sourcesContent":["#email{\n    .mat-form-field-wrapper {\n        padding-bottom: 0 !important;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
