import * as activityActions from './activity.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    blackboard: any;
    miniTiles: any;
    workbookTiles: any;
    activityTypes: any;
    selectedTool: String;
    selectedCardSize: number;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    blackboard: null,
    miniTiles: null,
    workbookTiles: null,
    activityTypes: null,
    selectedTool: "",
    selectedCardSize: 2,
};

export function reducer(state = initialState, action: activityActions.ActivityActions): State {
    switch (action.type) {
        case activityActions.ActivityActionTypes.setActivitySelectedTool: {
            return {
                ...state,
                selectedTool: action.payload.tool,
                selectedCardSize: action.payload.cardSize ?? state.selectedCardSize
            };
        }
        case activityActions.ActivityActionTypes.BlackboardInit: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case activityActions.ActivityActionTypes.BlackboardInitSuccess: {
            return {
                ...state,
                blackboard: action.payload,
                error: null
            };
        }
        case activityActions.ActivityActionTypes.BlackboardInitFail: {
            return {
                ...state,
                blackboard: null,
                error: action.payload
            };
        }
        case activityActions.ActivityActionTypes.MiniTilesInit: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case activityActions.ActivityActionTypes.MiniTilesInitSuccess: {
            return {
                ...state,
                miniTiles: JSON.parse(JSON.stringify(action.payload)),
                error: null
            };
        }
        case activityActions.ActivityActionTypes.MiniTilesInitFail: {
            return {
                ...state,
                miniTiles: null,
                error: action.payload
            };
        }

        case activityActions.ActivityActionTypes.WorkbookTilesInit: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case activityActions.ActivityActionTypes.WorkbookTilesInitSuccess: {
            return {
                ...state,
                workbookTiles: action.payload,
                error: null
            };
        }
        case activityActions.ActivityActionTypes.WorkbookTilesInitFail: {
            return {
                ...state,
                workbookTiles: null,
                error: action.payload
            };
        }
        case activityActions.ActivityActionTypes.GetAllActivityTypes: {
            return {
                ...state,
                pending: true,
            };
        }
        case activityActions.ActivityActionTypes.GetAllActivityTypesSuccess: {
            return {
                ...state,
                // NOTE: I can make sure "Blackboard" is called "Tileboard" here to make sure this applies across the entire app
                activityTypes: action.payload,
                error: null
            };
        }

        case activityActions.ActivityActionTypes.UpdateInitDataCourseWorkbookActivitySuccess: {
          let newState = JSON.parse(JSON.stringify(state));
          const activityTypeId = action.payload?.activityTypeId || action.payload?.activityId;
          if (newState[activityTypeId]?.lessonPlanStep) {
            newState[activityTypeId].lessonPlanStep = action.payload;
          } else if (newState[activityTypeId]?.courseWorkbookActivity) {
            newState[activityTypeId].courseWorkbookActivity = action.payload;
          }
          return {
              ...newState,
              error: null
          };
        }

        case activityActions.ActivityActionTypes.ClearActivityInitData: {
          return {
              ...state,
              blackboard: null,
              miniTiles: null,
              workbookTiles: null,
              error: null
          };
      }
        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const blackboardInit = (state: State) => state.blackboard;
export const miniTilesInit = (state: State) => state.miniTiles;
export const workbookTilesInit = (state: State) => state.workbookTiles;

