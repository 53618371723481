import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

@Component({
  selector: 'app-cancel-subscription',
  styleUrls: [
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ],
  template: `
    <div>
    <div>
      <h2 mat-dialog-title style="padding: 0; margin-bottom: 0px;">Cancel Subscription</h2>
    </div>
      <div class="mat-typography">
      <p style="margin-bottom: 1.5rem;">We're sorry to see you go. To help us improve, please let us know why you are cancelling your plan.</p>
      <form [formGroup]="cancelForm">
        <p>(Please select all that apply.)</p>
        <ng-container *ngFor="let reason of reasons">
          <mat-checkbox [formControlName]="reason.controlName" style="width: 100%">{{ reason.label }}</mat-checkbox>
        </ng-container>
        <p style="margin-top: 1rem;">Additional comments</p>
        <textarea formControlName="additionalComments" placeholder="What is one feature or area where you think Whizzimo can improve?" style="width: 100%; resize: none; min-height: 5rem; font-size: 1rem; font-family: 'Roboto';"></textarea>
      </form>
      <div *ngIf="formSubmitted && !cancelForm.valid" style="color: red; margin-top: 10px;">
      <p *ngIf="checkboxGroupInvalid()">Please select at least one reason.</p>
      <p *ngIf="cancelForm.controls['additionalComments'].hasError('required')">Additional comments are required.</p>
      <p *ngIf="cancelForm.controls['additionalComments'].hasError('minlength')">Additional comments must be at least 10 characters long.</p>
    </div>
      <div style="display: inline-flex; width: 100%; justify-content: flex-end; margin-top: 20px;">
        <button
          mat-flat-button
          class="btn-default"
          style="font-size: medium; width: 47%;"
          (click)="closeDialog()"
        >
          Never mind
        </button>
        <button
        mat-flat-button
        class="btn-default"
        style="font-size: medium; width: 47%;"
        (click)="confirmCancellation()"
      >
        Cancel Subscription
      </button>
      </div>
</div>
  </div>
  `
})
export class CancelSubscriptionComponent {
  cancelForm: FormGroup;
  functions;
  formSubmitted = false;

  reasons = [
    { controlName: 'reasonTooDifficult', label: 'Too difficult to use' },
    { controlName: 'reasonFeaturesMissing', label: 'Features I need are missing' },
    { controlName: 'reasonTooExpensive', label: 'Too expensive' },
    { controlName: 'reasonNoLongerNeeded', label: 'No longer needed' },
    { controlName: 'reasonTooManyBugs', label: 'Too many bugs' },
    { controlName: 'reasonUsingDifferentSoftware', label: 'I am using different software' },
    { controlName: 'reasonOther', label: 'Other' },
  ];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private fb: FormBuilder) {
    const reasonControls = this.reasons.reduce((acc, reason) => {
      acc[reason.controlName] = [false];
      return acc;
    }, {});

    console.log(reasonControls);

    this.cancelForm = this.fb.group({
      ...reasonControls,
      additionalComments: ['', [Validators.required, Validators.minLength(10)]]
    }, { validators: atLeastOneCheckboxChecked(this.reasons) });
  }

  checkboxGroupInvalid() {
    return this.cancelForm.hasError('atLeastOne') && this.formSubmitted;
  }


  confirmCancellation() {
    this.formSubmitted = true;
    if (this.cancelForm.valid) {
      const cancellationData = this.reasons.map(reason => ({
        reason: reason.label,
        value: this.cancelForm.controls[reason.controlName].value
      })).filter(reason => reason.value);

      this.functions.yes({
        reasons: cancellationData,
        additionalComments: this.cancelForm.controls['additionalComments'].value
      });
    }
  }


  closeDialog() {
    this.functions.no();
  }
}

function atLeastOneCheckboxChecked(reasons): ValidatorFn {
  return (group: AbstractControl): { [key: string]: any } | null => {
    const reasonsValues = reasons.map(reason => group.get(reason.controlName).value);
    if (reasonsValues.some(value => value)) {
      return null;
    }
    return { atLeastOne: true };
  };
}