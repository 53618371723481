// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
      display: flex;
      flex-direction: column;
      height: 100%;
    }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndvcmtib29rLWJ1aWxkZXIuY29tcG9uZW50LnRzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO01BQ00sYUFBYTtNQUNiLHNCQUFzQjtNQUN0QixZQUFZO0lBQ2QiLCJmaWxlIjoid29ya2Jvb2stYnVpbGRlci5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyI6aG9zdCB7XG4gICAgICBkaXNwbGF5OiBmbGV4O1xuICAgICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICAgIGhlaWdodDogMTAwJTtcbiAgICB9Il19 */`, "",{"version":3,"sources":["webpack://./src/core/components/workbooks/workbook-builder/workbook-builder.component.ts"],"names":[],"mappings":"AAAA;MACM,aAAa;MACb,sBAAsB;MACtB,YAAY;IACd;AACJ,gaAAga","sourcesContent":[":host {\n      display: flex;\n      flex-direction: column;\n      height: 100%;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
