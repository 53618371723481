// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      .cdk-drag-disabled {
        cursor: default;
      }
    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvdXJzZS13b3JrYm9vay1lZGl0LXRpbGVzLmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO01BQ007UUFDRSxlQUFlO01BQ2pCIiwiZmlsZSI6ImNvdXJzZS13b3JrYm9vay1lZGl0LXRpbGVzLmNvbXBvbmVudC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgLmNkay1kcmFnLWRpc2FibGVkIHtcbiAgICAgICAgY3Vyc29yOiBkZWZhdWx0O1xuICAgICAgfVxuICAgICJdfQ== */`, "",{"version":3,"sources":["webpack://./src/core/components/course-workbook-edit/course-workbook-edit-tiles.component.ts"],"names":[],"mappings":";MACM;QACE,eAAe;MACjB;;AAEN,wYAAwY","sourcesContent":["\n      .cdk-drag-disabled {\n        cursor: default;\n      }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
