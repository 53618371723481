// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container, .item-row {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.item-row {
  flex-direction: row;
}

.toggle {
  width: 100%;
}

.help_icon {
  margin-left: 10px;
  cursor: pointer;
}

:host ::ng-deep .mat-mdc-text-field-wrapper {
  padding: 0 24px !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIm5ldy1hZHZhbmNlZC5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNFLFlBQUE7RUFDQSxXQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0FBQ0Y7O0FBRUE7RUFFRSxtQkFBQTtBQUFGOztBQUdBO0VBQ0UsV0FBQTtBQUFGOztBQUdBO0VBQ0UsaUJBQUE7RUFDQSxlQUFBO0FBQUY7O0FBSUU7RUFDRSwwQkFBQTtBQURKIiwiZmlsZSI6Im5ldy1hZHZhbmNlZC5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5jb250YWluZXIge1xuICBoZWlnaHQ6IDEwMCU7XG4gIHdpZHRoOiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xufVxuXG4uaXRlbS1yb3cge1xuICBAZXh0ZW5kIC5jb250YWluZXI7XG4gIGZsZXgtZGlyZWN0aW9uOiByb3c7XG59XG5cbi50b2dnbGUge1xuICB3aWR0aDogMTAwJTtcbn1cblxuLmhlbHBfaWNvbiB7XG4gIG1hcmdpbi1sZWZ0OiAxMHB4O1xuICBjdXJzb3I6IHBvaW50ZXI7XG59XG5cbjpob3N0IDo6bmctZGVlcCB7XG4gIC5tYXQtbWRjLXRleHQtZmllbGQtd3JhcHBlciB7XG4gICAgcGFkZGluZzogMCAyNHB4ICFpbXBvcnRhbnQ7XG4gIH0gXG59Il19 */`, "",{"version":3,"sources":["webpack://./src/Settings/new-advanced/new-advanced.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,aAAA;EACA,sBAAA;AACF;;AAEA;EAEE,mBAAA;AAAF;;AAGA;EACE,WAAA;AAAF;;AAGA;EACE,iBAAA;EACA,eAAA;AAAF;;AAIE;EACE,0BAAA;AADJ;AACA,g4BAAg4B","sourcesContent":[".container {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n}\n\n.item-row {\n  @extend .container;\n  flex-direction: row;\n}\n\n.toggle {\n  width: 100%;\n}\n\n.help_icon {\n  margin-left: 10px;\n  cursor: pointer;\n}\n\n:host ::ng-deep {\n  .mat-mdc-text-field-wrapper {\n    padding: 0 24px !important;\n  } \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
