import { AmplitudeService } from '../../services/amplitude.service';
import * as courseActions from './courses.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<any> {
    selectedId: string;
    id: string;
    pending: boolean;
    error: any;
    succeeded: boolean;
    all: boolean;
    course: any;
}

export const adapter: EntityAdapter<string> = createEntityAdapter({
    selectId: (course: any) => course._id
});
const initialState: State = adapter.getInitialState({
    selectedId: null,
    id: null,
    pending: false,
    error: null,
    succeeded: false,
    all: false,
    course: null
});


export function reducer(state = initialState, action: courseActions.CoursesActions): State {
    switch (action.type) {
        case courseActions.CourseActionTypes.CreateCourseSuccess: {
            const updatedState = adapter.addOne((action.payload.data), state);
            const totalEntities = updatedState.ids.length;
        
            AmplitudeService.identifyUser({
                courseCount: totalEntities
            });

            (<any>window).Intercom("update", {
                courseCount: totalEntities
            });

            return {
                // ...state,
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }
        case courseActions.CourseActionTypes.CreateCourseSuccessWithoutRedirect: {
            const updatedState = adapter.addOne((action.payload.data), state);
            const totalEntities = updatedState.ids.length;

            AmplitudeService.identifyUser({
                courseCount: totalEntities
            });

            (<any>window).Intercom("update", {
                courseCount: totalEntities
            });
            return {
                // ...state,
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case courseActions.CourseActionTypes.UpdateCourseSuccess: {
            return {
                ...adapter.updateOne({id: action.payload._id, changes: action.payload}, state),
                pending: true,
                succeeded: false,
                error: null
            };

        }
        case courseActions.CourseActionTypes.DeleteCourse: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
                all: state.all
            };
        }
        case courseActions.CourseActionTypes.DeleteCourseSuccess: {
            const updatedState = adapter.removeOne(action.payload, state);
            const totalEntities = updatedState.ids.length;

            AmplitudeService.identifyUser({
                courseCount: totalEntities
            });

            (<any>window).Intercom("update", {
                courseCount: totalEntities
            });

            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case courseActions.CourseActionTypes.DeleteCourseFail: {
            return {
                ...state,
                pending: false,
                succeeded: false,
                error: action.payload,
            };
        }
        case courseActions.CourseActionTypes.GetAllCourse: {
            return {
                ...state,
                selectedId: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case courseActions.CourseActionTypes.GetAllCourseSuccess: {
            const courses = action.payload.slice();
            courses.sort((a, b) => a.order !== b.order ? a.order < b.order ? -1 : 1 : 0);

            const updatedState = adapter.addMany(courses, state);
            const totalEntities = updatedState.ids.length;

            AmplitudeService.identifyUser({
                courseCount: totalEntities
            });

            (<any>window).Intercom("update", {
                courseCount: totalEntities
            });

            return {
                ...updatedState,
                succeeded: false,
                pending: false,
                // all: state.all,
                all: true,
                error: null
            };
        }
        case courseActions.CourseActionTypes.GetAllCourseFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                selectedId: null,
                error: action.payload
            };
        }
        case courseActions.CourseActionTypes.ClearCourses: {
            return {
                ...adapter.removeAll(state),
                succeeded: false,
                pending: false,
                error: null
            };
        }
        case courseActions.CourseActionTypes.GetCourse: {
            return {
                ...state,
                id: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case courseActions.CourseActionTypes.GetCourseSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                id: state.id,
                course: action.payload,
                error: null
            };
        }
        case courseActions.CourseActionTypes.GetCourseFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                error: action.payload
            };
        }
        case courseActions.CourseActionTypes.CopyCourseSuccess: {
            const updatedState = adapter.addOne((action.payload), state);
            const totalEntities = updatedState.ids.length;

            AmplitudeService.identifyUser({
                courseCount: totalEntities
            });

            (<any>window).Intercom("update", {
                courseCount: totalEntities
            });
            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }
        case courseActions.CourseActionTypes.CopyCourseSuccessWithoutRedirect: {
            const updatedState = adapter.addOne((action.payload), state);
            const totalEntities = updatedState.ids.length;

            AmplitudeService.identifyUser({
                courseCount: totalEntities
            });

            (<any>window).Intercom("update", {
                courseCount: totalEntities
            });
            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case courseActions.CourseActionTypes.ReorderCourses: {
            let changes = action.payload.map(course => {
                return {
                    id: course._id,
                    changes: course
                };
            });
            return {
                ...adapter.updateMany(changes, state),
                pending: false,
                succeeded: true,
                error: null
            };
        }

        default:
            return state;
    }
}

export const {
    selectIds: SelectAllIds,
    selectEntities: SelectAllEntities,
    selectAll: SelectAllCourses,
} = adapter.getSelectors();

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getAllSucceeded = (state: State) => state.all;
export const getError = (state: State) => state.error;
export const getSelectedCourse = (state: State) => {
    return state.entities[state.selectedId];
};
export const getCourse = (state: State) => state.course;
export const getAllCourses = (state: State) => {
    let courses = SelectAllCourses(state);
    courses = courses.sort((a: any, b: any) => (a.order > b.order) ? 1 : ((b.order > a.order) ? -1 : 0));
    return [...courses];
};
