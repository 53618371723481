// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
  font-weight: bold;
  padding-top: 24px;
}

.body {
  color: gray;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.button-holder {
  display: flex;
  justify-content: flex-end;
  padding: 0 24px 24px;
}

::ng-deep .modal-container mat-mdc-dialog-container {
  border-radius: 8px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdC1wbGFuLm1vZGFsLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBRUE7RUFFSSxpQkFBQTtFQUNBLGlCQUFBO0FBRko7O0FBSUE7RUFDSSxXQUFBO0VBQ0EsYUFBQTtFQUNBLHNCQUFBO0VBQ0EsbUJBQUE7RUFDQSx1QkFBQTtBQURKOztBQUdBO0VBQ0ksYUFBQTtFQUNBLHlCQUFBO0VBQ0Esb0JBQUE7QUFBSjs7QUFHSTtFQUNJLGtCQUFBO0FBQVIiLCJmaWxlIjoic2VsZWN0LXBsYW4ubW9kYWwuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi8vIEBpbXBvcnQgJy4uLy4uL3N0eWxlcy5zY3NzJztcbi8vIEBpbXBvcnQgXCIuLi8uLi9jb21wb25lbnRfdmFyaWFibGUuc2Nzc1wiO1xuLmhlYWRlcntcbiAgICAvLyBjb2xvcjogJHByaW1hcnktZGFyaztcbiAgICBmb250LXdlaWdodDogYm9sZDtcbiAgICBwYWRkaW5nLXRvcDogMjRweDtcbn1cbi5ib2R5e1xuICAgIGNvbG9yOiBncmF5O1xuICAgIGRpc3BsYXk6IGZsZXg7XG4gICAgZmxleC1kaXJlY3Rpb246IGNvbHVtbjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGp1c3RpZnktY29udGVudDogY2VudGVyO1xufVxuLmJ1dHRvbi1ob2xkZXJ7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGZsZXgtZW5kO1xuICAgIHBhZGRpbmc6IDAgMjRweCAyNHB4O1xufVxuOjpuZy1kZWVwIC5tb2RhbC1jb250YWluZXIge1xuICAgIG1hdC1tZGMtZGlhbG9nLWNvbnRhaW5lciB7XG4gICAgICAgIGJvcmRlci1yYWRpdXM6IDhweDtcbiAgICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/app/select-plan-modal/select-plan.modal.scss"],"names":[],"mappings":"AAEA;EAEI,iBAAA;EACA,iBAAA;AAFJ;;AAIA;EACI,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AADJ;;AAGA;EACI,aAAA;EACA,yBAAA;EACA,oBAAA;AAAJ;;AAGI;EACI,kBAAA;AAAR;AACA,olCAAolC","sourcesContent":["// @import '../../styles.scss';\n// @import \"../../component_variable.scss\";\n.header{\n    // color: $primary-dark;\n    font-weight: bold;\n    padding-top: 24px;\n}\n.body{\n    color: gray;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n.button-holder{\n    display: flex;\n    justify-content: flex-end;\n    padding: 0 24px 24px;\n}\n::ng-deep .modal-container {\n    mat-mdc-dialog-container {\n        border-radius: 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
