import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { API_DIR } from '../../environments/environment';

@Injectable()
export class AnalyticsService {
    constructor(private http: HttpClient) {
    }

    sendDataToAmplitude(identifyData: any) {
        return new Observable(observer => {
            this.http
                .post(`${API_DIR}/analytics`, identifyData)
                .subscribe(
                    () => observer.next(),
                    error => observer.error(error)
                );
        });
    }
}


