import 'zone.js/mix';
import 'reflect-metadata';
import '../polyfills';
import { NgModule, ErrorHandler } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { SettingsService } from '../core/services/new-settings.service';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {BrowserModule, HammerModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppRoutingModule } from './app-routing.module';
import { CoreModule } from '../core/core.module';
import { CurriculumModule } from '../Curriculum/curriculum.module';
import { CoursesModule } from '../Courses/courses.module';
import { LessonsModule } from '../Lessons/lessons.module';
import { LoginModule } from '../Login/login.module';
import { RegistrationModule } from '../registration/registration.module';
import { PasswordresetModule } from '../passwordreset/passwordreset.module';

import { ElectronService } from '../core/services/electron.service';

import { WebviewDirective } from './directives/webview.directive';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { SettingsModule } from '../Settings/settings.module';
import { WordlistModule } from '../wordlist/wordlist.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {WorkbookBuilderModule} from '../core/components/workbooks/workbook-builder/workbook-builder.module';
import {DndModule} from 'ng2-dnd';
import {LessonPlansModule} from '../LessonPlans/lessons-plans.module';
import {CourseWorkbookEditModule} from '../core/components/course-workbook-edit/course-workbook-edit.module';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { NavbarListenerService } from '../core/services/navbar-listener-service..service';
import {API_DIR, APP_ID, BUG_SNAG_API_KEY} from '../environments/environment';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GlobalErrorHandlerService } from './global-error-handler.service';
import { provideUserIdleConfig } from 'angular-user-idle';
import {SegmentModule} from 'ngx-segment-analytics';
// import {RollbarErrorHandler, rollbarFactory, RollbarService} from './components/ErrorHandlers/RollbarErrorHandler';
import { MAT_RIPPLE_GLOBAL_OPTIONS } from '@angular/material/core';
// import {MixpanelService} from './MixpanelService';
import {NavigationEnd, Router} from "@angular/router";
import {TermsOfServiceService} from '../core/services/terms-of-service.service';
import { PrivacyPolicyService } from '../core/services/privacy-policy-service';
import { AmplitudeService } from '../core/services/amplitude.service';

if (localStorage.getItem('profile')) {
  const profile: any = JSON.parse(localStorage.profile);
}

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const config: SocketIoConfig = { url: API_DIR, options: {upgrade: false, transports: ['websocket']} };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotFoundComponent,
    WebviewDirective
  ],
  imports: [
    SegmentModule.forRoot({ apiKey: 'zddugYfWL1cHM47kicHnjdWFfiBrG0hB', debug: true, loadOnInitialization: true }),
    BrowserAnimationsModule,
    BrowserModule,
    NgScrollbarModule,
    AppRoutingModule,
    FontAwesomeModule,
    CoreModule,
    StoreModule.forRoot({}),
    EffectsModule.forRoot(),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: false, // Restrict extension to log-only mode
    }),
    CurriculumModule,
    WorkbookBuilderModule,
    CoursesModule,
    LessonsModule,
    LessonPlansModule,
    LoginModule,
    HttpClientModule,
    SettingsModule,
    DndModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    RegistrationModule,
    PasswordresetModule,
    WordlistModule,
    // ActivityModule,
    CourseWorkbookEditModule,
    HammerModule,
    SocketIoModule.forRoot(config)
  ],
  providers: [
    TermsOfServiceService,
    PrivacyPolicyService,
    ElectronService,
    SettingsService,
    NavbarListenerService,
    // MixpanelService,
    AmplitudeService,
    {provide: Window, useValue: window},
    { provide: ErrorHandler, useClass: GlobalErrorHandlerService},
    // { provide: ErrorHandler, useClass: RollbarErrorHandler },
    // { provide: RollbarService, useFactory: rollbarFactory },
    // idle should be 4 hours and 15 minutes
    provideUserIdleConfig({
      idle: (60 * 60 * 4) + (60 * 15),
      timeout: 1
    }),
    {provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: {
      disabled: true,
      animation: {
        enterDuration: 0,
        exitDuration: 0
      }
    }}
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private router: Router) {
    AmplitudeService.init();
    this.router.events.pipe().subscribe((event) => {
      if(
        event instanceof NavigationEnd
        && this.router.url !== '/login'
        && this.router.url !== '/registration'
        && this.router.url !== '/passwordreset'
      ) {

      }
    });
  }
}
