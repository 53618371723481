import { Injectable } from '@angular/core';
import * as amplitude from '@amplitude/analytics-browser';
// import { sessionReplayPlugin } from '@amplitude/plugin-session-replay-browser';
import { AMPLITUDE_API_KEY, AMPLITUDE_IDENTIFY_URL } from '../../environments/environment';
import { AppConfig } from '../../environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AmplitudeService {

    private static initialized: boolean = false;

    /**
     * Initializes the Amplitude SDK.
     */
    static init(): void {
      if (this.initialized) {
        return;
      }
  
      try {
        let dynamicConfigs: { [key: string]: string } = {};
  
        // Add dynamic configs like app version
        const appVersion = localStorage.getItem('appversion');
        if (appVersion) {
          dynamicConfigs.app_version = appVersion;
        }
  
        amplitude.init(AMPLITUDE_API_KEY, {
          fetchRemoteConfig: true,
          autocapture: true,
          ...dynamicConfigs,
        });
  
        this.initialized = true;
      } catch (error) {
        console.error('Amplitude initialization failed:', error);
      }
    }
  
    /**
     * Logs an event to Amplitude.
     * @param eventName Name of the event.
     * @param eventProperties Optional properties to associate with the event.
     */
    static logEvent(eventName: string, eventProperties?: Record<string, any>): void {
      if (!this.initialized) {
        console.warn('Amplitude not initialized. Event not logged:', eventName);
        return;
      }
      amplitude.track(eventName, eventProperties);
    }
  
    /**
     * Identifies a user in Amplitude.
     * @param identityTraits Traits to associate with the user.
     */
    static identifyUser(identityTraits: Record<string, any>): void {
      if (!this.initialized) {
        console.warn('Amplitude not initialized. User traits not set:', identityTraits);
        return;
      }
      const identify = new amplitude.Identify();
  
      for (const key in identityTraits) {
        identify.set(key, identityTraits[key]);
      }
  
      amplitude.identify(identify);
    }
  
    /**
     * Sets the user ID in Amplitude.
     * @param userId The user ID to set.
     */
    static setUserId(userId: string): void {
      if (!this.initialized) {
        console.warn('Amplitude not initialized. User ID not set:', userId);
        return;
      }
      amplitude.setUserId(userId);
    }
  
    /**
     * Sets a group for the user.
     * @param groupType Type of the group.
     * @param groupName Name of the group.
     */
    static setGroup(groupType: string, groupName: string): void {
      if (!this.initialized) {
        console.warn('Amplitude not initialized. Group not set:', groupType, groupName);
        return;
      }
      amplitude.setGroup(groupType, groupName);
    }

    static identifyUserImmediately(identityTraits: Record<string, any>): void {
        if (!this.initialized) {
            console.warn('Amplitude not initialized. User traits not set:', identityTraits);
            return;
        }

        // Create a API call to the Amplitude Identify API
        const amplitudeEndpoint = AMPLITUDE_IDENTIFY_URL;

        fetch(amplitudeEndpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                api_key: AMPLITUDE_API_KEY,
                identification: {
                    user_id: identityTraits.user_id,
                    user_properties: identityTraits,
                },
            }),
        }).then((response) => {
            if (response.ok) {
                console.log('Amplitude Identify API success:', response);
            } else {
                console.error('Amplitude Identify API failed:', response);
            }
        }
        ).catch((error) => {
            console.error('Amplitude Identify API error:', error);
        });
    }

    // // Stop session replay tracking
    // async stopSessionReplay(): Promise<void> {
    //     await amplitude.remove(this.sessionReplayTracking.name).promise;
    // }

    // // Start session replay tracking
    // async startSessionReplay(): Promise<void> {
    //     await amplitude.add(this.sessionReplayTracking).promise;
    // }
}