import { AmplitudeService } from '../../services/amplitude.service';
import * as SharingActions from './sharing.actions';

export interface State {
    pending: boolean;
    error: any;
    succeeded: boolean;
    sharingData: any;
    applied: any;
}

const initialState: State = {
    pending: false,
    error: null,
    succeeded: false,
    sharingData: null,
    applied: null
};

export function reducer(state = initialState, action: SharingActions.SharingDataActions): State {
    switch (action.type) {
        case SharingActions.SharingActionTypes.GetSharingData: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case SharingActions.SharingActionTypes.GetSharingDataSuccess: {
            AmplitudeService.identifyUser({
                bundleCount: action.payload.length
            });

            (window as any).Intercom('update', {
                bundleCount: action.payload.length
            });
            
            return {
                ...state,
                sharingData: action.payload,
                succeeded: true,
                error: null
            };
        }
        case SharingActions.SharingActionTypes.GetSharingDataFail: {
            return {
                ...state,
                sharingData: null,
                error: action.payload
            };
        }
        
        case SharingActions.SharingActionTypes.SharingRename: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case SharingActions.SharingActionTypes.SharingRenameSuccess: {
            let sharingData = JSON.parse(JSON.stringify(state.sharingData));
            const index = sharingData.findIndex(item => item._id === action.payload.id);
            if (index > -1) {
                sharingData[index] = {...sharingData[index], ...action.payload};
            }
            return {
                ...state,
                sharingData,
                pending: false,
                error: null
            };
        }
        case SharingActions.SharingActionTypes.SharingApply: {
            return {
                ...state,
                applied: null,
                pending: true,
                error: null
            };
        }
        case SharingActions.SharingActionTypes.SharingApplySuccess: {
            return {
                ...state,
                applied: action.payload,
                error: null,
                pending: false,
            };
        }
        case SharingActions.SharingActionTypes.SharingApplyFail: {
            return {
                ...state,
                applied: {},
                error: null,
                pending: false,
            };
        }


        case SharingActions.SharingActionTypes.SharingApplyReset: {
            return {
                ...state,
                applied: null,
                error: null,
                pending: false,
            };
        }

        case SharingActions.SharingActionTypes.SharingDelete: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case SharingActions.SharingActionTypes.SharingDeleteSuccess: {
            let sharingData = JSON.parse(JSON.stringify(state.sharingData));
            const index = sharingData.findIndex(item => item._id === action.payload);
            if (index > -1) {
                sharingData.splice(index, 1);
            }

            AmplitudeService.identifyUser({
                bundleCount: sharingData.length
            });

            (window as any).Intercom('update', {
                bundleCount: sharingData.length
            });

            return {
                ...state,
                sharingData,
                pending: false,
                error: null
            };
        }

        case SharingActions.SharingActionTypes.SharingCreate: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case SharingActions.SharingActionTypes.SharingCreateSuccess: {

            AmplitudeService.identifyUser({
                bundleCount: state.sharingData.length + 1
            });

            (window as any).Intercom('update', {
                bundleCount: state.sharingData.length + 1
            });

            return {
                ...state,
                sharingData: [...state.sharingData, ...[action.payload]],
                pending: false,
                error: null
            };
        }

        default:
            return state;
    }
}

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getError = (state: State) => state.error;
export const GetSharingData = (state: State) => state.sharingData;
export const GetApplied = (state: State) => state.applied;
