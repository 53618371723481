import {Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges} from '@angular/core';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-course-workbook-edit-workbook-items',
  templateUrl: './html/course-workbook-edit-workbook-items.html',
})

export class CourseWorkbookEditWorkbookItemsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() courseWorkbookItemDetails: any[] = [];
  @Input() nameField: string;
  @Input() lessonPlanStep: any;
  @Input() changeWorkbookObservable: Observable<any>;
  @Output() updateLessonPlanStepEvent: EventEmitter<any> = new EventEmitter<any>();
  public courseWorkbookItemsFiltered: any[] = [];
  public courseWorkbookItemsFilteredSubject: any = new BehaviorSubject<any>(this.courseWorkbookItemsFiltered);
  public courseWorkbookItemsFiltered$: any = this.courseWorkbookItemsFilteredSubject.asObservable();

  public courseWorkbookItems: any[] = [];
  public courseWorkbookSubject: any = new BehaviorSubject<any>(this.courseWorkbookItems);
  public courseWorkbookItems$ = this.courseWorkbookSubject.asObservable();
  public showMoreWords = false;
  subscriptions = new Subscription();
  courseWorkbookListEmpty = false;
  pageIndex = 0;
  pageSize = 5;

  @Input() setCourseWorkbookItems(courseWorkbookItem: any) {
    this.courseWorkbookSubject.next(courseWorkbookItem);
  }

  constructor(private async: AsyncPipe) {
  }

  ngOnInit(): void {
      this.subscriptions.add(this.changeWorkbookObservable
        .subscribe(selectedCourseWorkbook => {
          let courseWorkbookItem = selectedCourseWorkbook[`${this.nameField.toLowerCase()}s`][0];
          const courseWorkbookItems = courseWorkbookItem ? courseWorkbookItem[`${this.nameField.toLowerCase()}s`] : [];
          const lessonSentences = this.lessonPlanStep[`${this.nameField.toLowerCase()}s`];
          if (!lessonSentences || lessonSentences.length === 0 ) {
            const noSentence = {
              phrase: "No Sentence Parts in Lesson Plan",
              sentence: "No Sentences in Lesson Plan",
              passage: "No Passages in Lesson Plan",
              file: "No Files in Lesson Plan"
            };
            this.lessonPlanStep[`${this.nameField.toLowerCase()}s`] = [
              {
                _id: "0",
                [`${this.nameField.toLowerCase()}`]: noSentence[`${this.nameField.toLowerCase()}`]
              }
            ];
          }
          let courseWorkbookItemsFiltered = courseWorkbookItems.filter(cwbk => {
            if (lessonSentences) {
              const index = lessonSentences.findIndex(lps => {
                return lps._id === cwbk._id || (lps.id && lps.id === cwbk.oldId);
              });
              return index < 0;
            }
            return true;
          });

          if (this.courseWorkbookListEmpty) {
            const emptyIndex = courseWorkbookItemsFiltered.findIndex(cwbk => cwbk._id === 0);
            if (emptyIndex >= 0) {
              courseWorkbookItemsFiltered.splice(emptyIndex, 1);
            }
            this.courseWorkbookListEmpty = false;
          }
          this.setCourseWorkbookItemsFiltered(selectedCourseWorkbook);
        }));
  }

  ngOnChanges(changes) {
    if (changes.lessonPlanStep) {
      const value = changes.lessonPlanStep.currentValue;
      const items = value[`${this.nameField.toLowerCase()}s`];
      if (!items || items.length === 0 ) {
        const noSentence = {
          phrase: "No Sentence Parts in Lesson Plan",
          sentence: "No Sentences in Lesson Plan",
          passage: "No Passages in Lesson Plan",
          file: "No Files in Lesson Plan"
        };
        this.lessonPlanStep[`${this.nameField.toLowerCase()}s`] = [
          {
            _id: "0",
            [`${this.nameField.toLowerCase()}`]: noSentence[`${this.nameField.toLowerCase()}`]
          }
        ];
      }
    }
  }

  setCourseWorkbookItemsFiltered(selectedCourseWorkbook) {
    if (!selectedCourseWorkbook) {
      return;
    }

    console.log(selectedCourseWorkbook);
    
    const courseWorkbookItems = selectedCourseWorkbook[`${this.nameField.toLowerCase()}s`][0][`${this.nameField.toLowerCase()}s`];
    const lessonSentences = this.lessonPlanStep[`${this.nameField.toLowerCase()}s`];

    let courseWorkbookItemsFiltered = courseWorkbookItems.filter(cwbk => {
      if (lessonSentences) {
        const index = lessonSentences.findIndex(lps => {
          return lps._id === cwbk._id || (lps.id && lps.id === cwbk.oldId);
        });
        return index < 0;
      }
      return true;
    });

    if (this.courseWorkbookListEmpty) {
      const emptyIndex = courseWorkbookItemsFiltered.findIndex(cwbk => cwbk._id === 0);
      if (emptyIndex >= 0) {
        courseWorkbookItemsFiltered.splice(emptyIndex, 1);
      }
      this.courseWorkbookListEmpty = false;
    }

    if (courseWorkbookItemsFiltered.length === 0) {
      courseWorkbookItemsFiltered.push({
        [`${this.nameField.toLowerCase()}`]:
        `All ${(this.nameField.toLowerCase() === 'phrase'
        ? 'sentence part'
        : this.nameField.toLowerCase())}s added`,
         _id: 0
      });
      this.courseWorkbookListEmpty = true;
    }
    if (this.nameField.toLowerCase() === 'file') {
      courseWorkbookItemsFiltered = courseWorkbookItemsFiltered.sort((a, b) => {
        if ((a.file?.file?.toLowerCase() > b.file?.file?.toLowerCase()) ||
        (a.file?.name?.toLowerCase() > b.file?.name?.toLowerCase())) {
          return 1;
        }

        if ((a.file?.file?.toLowerCase() < b.file?.file?.toLowerCase()) ||
        (a.file?.name?.toLowerCase() < b.file?.name?.toLowerCase())) {
          return -1;
        }

        return 0;
      });

    } else {
      courseWorkbookItemsFiltered = courseWorkbookItemsFiltered.sort((a, b) => {
        if (a[`${this.nameField.toLowerCase()}`].toLowerCase() > b[`${this.nameField.toLowerCase()}`].toLowerCase()) {
          return 1;
        }

        if (a[`${this.nameField.toLowerCase()}`].toLowerCase() < b[`${this.nameField.toLowerCase()}`].toLowerCase()) {
          return -1;
        }

        return 0;
      });

    }

    this.courseWorkbookItemsFilteredSubject.next(courseWorkbookItemsFiltered);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  filterCourseWorkbookList() {
    this.courseWorkbookItems$
    .pipe(take(1))
    .subscribe((courseWorkbookItems: any) => {
      this.courseWorkbookItemsFiltered = courseWorkbookItems.filter(cwbk => {
        const index = this.lessonPlanStep[`${this.nameField.toLowerCase()}s`].findIndex(lps => {
          return lps._id === cwbk._id || (lps.id && lps.id === cwbk.oldId);
        });

        return index < 0;
      });

      if (this.courseWorkbookListEmpty) {
        const emptyIndex = this.courseWorkbookItemsFiltered.findIndex(cwbk => cwbk._id === 0);
        if (emptyIndex >= 0) {
          this.courseWorkbookItemsFiltered.splice(emptyIndex, 1);
        }
        this.courseWorkbookListEmpty = false;
      }

      if (this.courseWorkbookItemsFiltered.length === 0) {
        this.courseWorkbookItemsFiltered.push({
          [`${this.nameField.toLowerCase()}`]:
          `All ${(this.nameField.toLowerCase() === 'phrase'
          ? 'sentence part'
          : this.nameField.toLowerCase())}s added`,
           _id: 0
        });
        this.courseWorkbookListEmpty = true;
      }

      this.courseWorkbookItemsFiltered = this.courseWorkbookItemsFiltered.sort((a, b) => {
        if (a[`${this.nameField.toLowerCase()}`].toLowerCase() > b[`${this.nameField.toLowerCase()}`].toLowerCase()) {
          return 1;
        }

        if (a[`${this.nameField.toLowerCase()}`].toLowerCase() < b[`${this.nameField.toLowerCase()}`].toLowerCase()) {
          return -1;
        }

        return 0;
      });

    });
  }

  reorderItems($event: CdkDragDrop<number, any>) {
    const currentItemType = $event.container.element.nativeElement.id;
    const arrIds = $event.previousContainer.element.nativeElement.id.split(' ');
    const previousItemType = arrIds[0];
    const id = arrIds[1];

    // because of bugs with cdkDragable and multiline horizontal lists, the two lists here are comprised of a draggableGroup, meaning that each
    // individual item in the list is its own droppable list. Because of this I need to use the container id's to tell which item belongs to which list
    // and decide what action to take depending on where that item is dropped

    const courseWorkbookItemsFiltered: any = this.async.transform(this.courseWorkbookItemsFiltered$);
    // if workbook item is moved into the course workbooks items list, simply add that item to the course workbook item list
    if (previousItemType === 'courseWorkbookItemsList' && currentItemType === 'lessonPlanItemsList') {
      const index = courseWorkbookItemsFiltered.findIndex(item => item._id === id);
      this.lessonPlanStep[this.nameField + 's'].splice($event.container.data, 0, courseWorkbookItemsFiltered[index]);
    }

    // if a tile is moved within the lesson plan items list, reorder the array
    if (previousItemType === 'lessonPlanItemsList' && currentItemType === 'lessonPlanItemsList') {
      const movedItem = this.lessonPlanStep[this.nameField + 's'].splice($event.previousContainer.data, 1)[0];
      const newIndex = $event.container.data;
      this.lessonPlanStep[this.nameField + 's'].splice(newIndex, 0, movedItem);
    }

    this.lessonPlanStep[this.nameField + 's'] = this.lessonPlanStep[this.nameField + 's'].slice();
    this.updateLessonPlanStep();
  }

  returnItem($event: CdkDragDrop<number, any>) {
    const arrTypes = $event.container.element.nativeElement.id.split(' ');
    const currentItemType = arrTypes[0];
    const previousItemType = $event.previousContainer.element.nativeElement.id;

    if (
      previousItemType === 'lessonPlanItemsList'
      && currentItemType === 'courseWorkbookItemsList'
    ) {
      this.lessonPlanStep[this.nameField + 's'].splice($event.previousContainer.data, 1);
      this.updateLessonPlanStep();
    }
  }

  private updateLessonPlanStep() {
    // becuase of the way drag and drop works with material, the list cannot be empty so a dummy value needs to be in the list when empty
    // remove that dummy value when items are added;
    if (this.lessonPlanStep[this.nameField + 's'].length > 0) {
      const index = this.lessonPlanStep[this.nameField + 's'].findIndex(lps => lps._id === "0");
      if (index >= 0) {
        this.lessonPlanStep[this.nameField + 's'].splice(index, 1);
      }
    }

    // this.filterCourseWorkbookList();
    this.updateLessonPlanStepEvent.emit(this.lessonPlanStep);
  }

  public toggleShowMore() {
    this.showMoreWords = !this.showMoreWords;
  }

  changePage(event) {
    this.pageIndex = event.pageIndex;
    this.pageSize = event.pageSize;
  }
}
