import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Subject } from 'rxjs';
import { getCurrentSetting } from '../../core/store';
import { PdfSizes } from '../../models/pdf-sizes';

export type FlashcardSplit = 'Word' | 'Syllables' | 'Onset/Rime' | 'Tiles' | 'Toggle';
export const fontSizes = [16, 24, 32, 40, 48, 56, 64]

@Injectable({
  providedIn: 'root'
})
export class WorkbookActivityService {
  fontSizes = fontSizes;
  togglePdfSelector$ = new Subject();

  isLowerCase$ = new BehaviorSubject(true);
  get isLowerCase() {
    return this.isLowerCase$.getValue();
  }

  isMulticardView$ = new BehaviorSubject(false);
  get isMulticardView() {
    return this.isMulticardView$.getValue();
  }

  cardSize$ = new BehaviorSubject(2);
  get cardSize() {
    return this.cardSize$.getValue();
  }

  tileSize$ = new BehaviorSubject(2);
  get tileSize() {
    return this.tileSize$.getValue();
  }

  pdfSize$ = new BehaviorSubject(PdfSizes.large.value);
  get pdfSize() {
    return this.pdfSize$.getValue();
  }

  showHorizontalLines$ = new BehaviorSubject(false);
  get showHorizontalLines() {
    return this.showHorizontalLines$.getValue();
  }

  flashcardView$ = new BehaviorSubject<FlashcardSplit>(null);
  get flashcardView() {
    return this.flashcardView$.getValue();
  }

  wordSplitOptions$ = new BehaviorSubject<FlashcardSplit[]>(null);
  get wordSplitOptions() {
    return this.wordSplitOptions$.getValue();
  }

  bumpingTiles$ = new BehaviorSubject(true);
  get bumpingTiles() {
    return this.bumpingTiles$.getValue();
  }

  hideTiles$ = new BehaviorSubject(false);
  get hideTiles() {
    return this.hideTiles$.getValue();
  }

  toggleTrashCan$ = new Subject();

  showMissingTilesModal$ = new BehaviorSubject(false);
  pdfRotate$ = new BehaviorSubject(0);
  get pdfRotate() {
    return this.pdfRotate$.getValue();
  }

  blendMode$ = new BehaviorSubject(false);
  get blendMode() {
    return this.blendMode$.getValue();
  }

  constructor(private _store: Store) { 
    // TODO: need to make sure that it is destroyed
    this._store.select(getCurrentSetting)
      .subscribe(setting => {
        if (setting?.flashcards) {
          // this.flashcardView$.next(setting.flashcards.option);
          const option = setting.flashcards.option;
          const options = this.getWordSplitOptions(option ?? 0);
          this.wordSplitOptions$.next(options);
        }
      });
  }

  getWordSplitOptions(value: number): FlashcardSplit[] {
    const sets: FlashcardSplit[][] = [
      ['Word', 'Syllables', 'Onset/Rime', 'Tiles'],
      ['Word', 'Syllables', 'Tiles'],
      ['Word', 'Onset/Rime', 'Tiles'],
      ['Word', 'Toggle', 'Tiles']
    ];
    if (!sets[value]) { return []; }
    return sets[value];
  }
}
