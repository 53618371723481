// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.add-save-setting .mat-dialog-content .mat-form-field .mat-form-field-wrapper {
  padding-bottom: 0 !important;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZC1jb2xsZWN0aW9uLWRpYWxvZy5jb21wb25lbnQuc2NzcyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtFQUNJLDRCQUFBO0FBQ0oiLCJmaWxlIjoiYWRkLWNvbGxlY3Rpb24tZGlhbG9nLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmFkZC1zYXZlLXNldHRpbmcgLm1hdC1kaWFsb2ctY29udGVudCAubWF0LWZvcm0tZmllbGQgLm1hdC1mb3JtLWZpZWxkLXdyYXBwZXIge1xuICAgIHBhZGRpbmctYm90dG9tOiAwICFpbXBvcnRhbnQ7XG4gICAgXG59Il19 */`, "",{"version":3,"sources":["webpack://./src/core/components/add-collection-dialog/add-collection-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,4BAAA;AACJ;AACA,ocAAoc","sourcesContent":[".add-save-setting .mat-dialog-content .mat-form-field .mat-form-field-wrapper {\n    padding-bottom: 0 !important;\n    \n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
