// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.is-inactive-menu {
      opacity: 0.16;
    }
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNlbGVjdGFibGUtbGV0dGVyLXRpbGVzLmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiQUFBQTtNQUNNLGFBQWE7SUFDZiIsImZpbGUiOiJzZWxlY3RhYmxlLWxldHRlci10aWxlcy5jb21wb25lbnQudHMiLCJzb3VyY2VzQ29udGVudCI6WyIuaXMtaW5hY3RpdmUtbWVudSB7XG4gICAgICBvcGFjaXR5OiAwLjE2O1xuICAgIH0iXX0= */`, "",{"version":3,"sources":["webpack://./src/core/components/workbooks/workbook-builder/word-filters/selectable-letter-tiles.component.ts"],"names":[],"mappings":"AAAA;MACM,aAAa;IACf;AACJ,gWAAgW","sourcesContent":[".is-inactive-menu {\n      opacity: 0.16;\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
