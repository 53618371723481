// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.for-internal-container {
  border-top: 1px solid rgba(13, 2, 35, 0.08);
  margin: 2px 0 0;
}
.for-internal-container .add_cousre_modal_subscription {
  font-size: 16px;
  font-weight: bold;
  opacity: 0.32;
  line-height: 1;
  letter-spacing: normal;
}
.for-internal-container .input-container {
  margin: 0 24px;
}
.for-internal-container .input-container > mat-form-field {
  width: 100%;
}
.for-internal-container .input-container > mat-form-field .mat-form-field-wrapper {
  margin: 0;
}

.add_cousre_modal_subscription {
  margin: 24px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImFkZC1jb3Vyc2UtZGlhbG9nLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUFBO0VBQ0UsMkNBQUE7RUFDQSxlQUFBO0FBQ0Y7QUFDRTtFQUNFLGVBQUE7RUFDQSxpQkFBQTtFQUNBLGFBQUE7RUFDQSxjQUFBO0VBQ0Esc0JBQUE7QUFDSjtBQUVFO0VBQ0UsY0FBQTtBQUFKO0FBQ0k7RUFDRSxXQUFBO0FBQ047QUFDTTtFQUNFLFNBQUE7QUFDUjs7QUFJQTtFQUNFLFlBQUE7QUFERiIsImZpbGUiOiJhZGQtY291cnNlLWRpYWxvZy5jb21wb25lbnQuc2NzcyIsInNvdXJjZXNDb250ZW50IjpbIi5mb3ItaW50ZXJuYWwtY29udGFpbmVyIHtcbiAgYm9yZGVyLXRvcDogMXB4IHNvbGlkIHJnYmEoMTMsIDIsIDM1LCAwLjA4KTtcbiAgbWFyZ2luOiAycHggMCAwO1xuXG4gIC5hZGRfY291c3JlX21vZGFsX3N1YnNjcmlwdGlvbiB7XG4gICAgZm9udC1zaXplOiAxNnB4O1xuICAgIGZvbnQtd2VpZ2h0OiBib2xkO1xuICAgIG9wYWNpdHk6IDAuMzI7XG4gICAgbGluZS1oZWlnaHQ6IDE7XG4gICAgbGV0dGVyLXNwYWNpbmc6IG5vcm1hbDtcbiAgfVxuXG4gIC5pbnB1dC1jb250YWluZXIge1xuICAgIG1hcmdpbjogMCAyNHB4O1xuICAgID4gbWF0LWZvcm0tZmllbGQge1xuICAgICAgd2lkdGg6IDEwMCU7XG5cbiAgICAgIC5tYXQtZm9ybS1maWVsZC13cmFwcGVyIHtcbiAgICAgICAgbWFyZ2luOiAwO1xuICAgICAgfVxuICAgIH1cbiAgfVxufVxuLmFkZF9jb3VzcmVfbW9kYWxfc3Vic2NyaXB0aW9uIHtcbiAgbWFyZ2luOiAyNHB4O1xufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/core/components/add-course-dialog/add-course-dialog.component.scss"],"names":[],"mappings":"AAAA;EACE,2CAAA;EACA,eAAA;AACF;AACE;EACE,eAAA;EACA,iBAAA;EACA,aAAA;EACA,cAAA;EACA,sBAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AACI;EACE,WAAA;AACN;AACM;EACE,SAAA;AACR;;AAIA;EACE,YAAA;AADF;AACA,4lCAA4lC","sourcesContent":[".for-internal-container {\n  border-top: 1px solid rgba(13, 2, 35, 0.08);\n  margin: 2px 0 0;\n\n  .add_cousre_modal_subscription {\n    font-size: 16px;\n    font-weight: bold;\n    opacity: 0.32;\n    line-height: 1;\n    letter-spacing: normal;\n  }\n\n  .input-container {\n    margin: 0 24px;\n    > mat-form-field {\n      width: 100%;\n\n      .mat-form-field-wrapper {\n        margin: 0;\n      }\n    }\n  }\n}\n.add_cousre_modal_subscription {\n  margin: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
