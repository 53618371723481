import { CreateCourseWorkbook, GetCourseWorkbookSuccess } from './../course-workbook/course-workbook.actions';
import { CourseWorkbooksService } from './../../services/course-workbooks.service';
import { GetCourseWorkbook, RemoveWorkbookFromCourseSuccess } from '../course-workbook/course-workbook.actions';
import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, take, tap } from 'rxjs/operators';
import { WorkbooksService } from '../../services/workbooks.service';
import { RemoveWorkbookFromCourse, RemoveWorkbookFromCourseViaLibrary } from '../course-workbook/course-workbook.actions';
import * as actions from './workbook.actions';
import { Router } from '@angular/router';
import { WordsService } from '../../services/words.service';
import { AmplitudeService } from '../../services/amplitude.service';
import * as reducers from '../../store/index';


@Injectable()

export class WorkbooksEffect {
    constructor(
        private actions$: Actions,
        private workbooksService: WorkbooksService,
        private router: Router,
        private wordsService: WordsService,
        private courseWorkbooksService: CourseWorkbooksService,
        private amplitudeService: AmplitudeService,
        private store: Store
    ) {}
    getWorkbook$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.GetWorkbook),
            map((action: actions.GetWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.get(payload.workbookId).pipe(
                    switchMap((res: any) => {
                        if (payload.isFromLibrary) {
                            return [new actions.GetWorkbookSuccess(res)];
                        } else {
                            return [
                                new GetCourseWorkbookSuccess({
                                    ...payload.courseWorkbook,
                                    ...res
                                }),
                                new actions.GetWorkbookSuccess(res)];
                        }
                    }),
                    tap((payload: any) => {
                        this.router.navigate([`/workbook-builder/${payload.workbookId}`, {isFromLibrary: payload.isFromLibrary}]);
                    }),
                    catchError((error) => of(new actions.GetWorkbookFail(error.message)))
                );
            }),
        );
    });

    // getWorkbookSuccess$ = createEffect(() => {
    //     return this.actions$.pipe(
    //         ofType(actions.WorkbookActionTypes.GetWorkbookSuccess),
    //         map((action: actions.GetWorkbookSuccess) => action.payload),
    //         // tap((payload: any) => {
    //         //     let objData = null;
    //         //     if (!payload.isFromLibrary) {
    //         //         objData = {
    //         //             ...payload.courseWorkbook,
    //         //             ...payload.workbook
    //         //         };
    //         //     }
    //         //     this.store.dispatch(new GetCourseWorkbookSuccess(objData))
    //         // }),
    //         tap((payload: any) => {
    //             this.router.navigate([`/workbook-builder/${payload.workbook?._id}`, {isFromLibrary: payload.isFromLibrary}])
    //         }),
    //     );
    // }, {dispatch: false});

    GetWhizzimoLibraryWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.GetWhizzimoLibraryWorkbooks),
            map((action: actions.GetWhizzimoLibraryWorkbooks) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.getLibraryWorkbooks().pipe(
                    map((res: any) => new actions.GetWhizzimoLibraryWorkbooksSuccess(res)),
                    catchError((error) => of(new actions.GetWhizzimoLibraryWorkbooksFail(error.message)))
                );
            })
        );
    });



    GetMyLibraryWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.GetMyLibraryWorkbooks),
            map((action: actions.GetMyLibraryWorkbooks) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.getAll().pipe(
                    switchMap((res: any) => {
                      let libraryWorkbooks = JSON.parse(JSON.stringify(res));
                    //   libraryWorkbooks = libraryWorkbooks.sort((a, b) => {
                    //     return a.name.localeCompare(b.name);
                    //   });
                      return [new actions.ClearWorkbooks(), new actions.GetMyLibraryWorkbooksSuccess(libraryWorkbooks)];
                    }),
                    catchError((error) => of(new actions.GetMyLibraryWorkbooksFail(error.message)))
                );
            })
        );
    });

    createWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.CreateWorkbook),
            map((action: actions.CreateWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.create(payload.workbook).pipe(
                    map((res: any) => {     
                        return new actions.CreateWorkbookSuccess(res);
                    }),
                    catchError((error) => of(new actions.CreateWorkbookFail(error.message)))
                );
                // const isFromLibrary = payload.isFromLibrary;
                // const workbookId = payload.workbookId;
                // return this.workbooksService.get(workbookId).pipe(
                //     switchMap((res: any) => {
                //         if (payload.isFromLibrary) {
                //             return [new actions.CreateWorkbookSuccess(res)]
                //         } else {
                //             return [
                //                 new GetCourseWorkbookSuccess({
                //                     ...payload.courseWorkbook,
                //                     ...res
                //                 }),
                //                 new actions.CreateWorkbookSuccess(res)]
                //         }
                //     }),
                //     tap((payload: any) => {
                //         this.router.navigate([`/workbook-builder/${workbookId}`, {isFromLibrary}])
                //     }),
                //     catchError((error) => of(new actions.GetWorkbookFail(error.message)))
                // );
            }),
        );
    });

    updateWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.UpdateWorkbook),
            map((action: actions.UpdateWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.update(payload.data, payload._id).pipe(
                    map((res: any) => new actions.UpdateWorkbookSuccess(payload)),
                    catchError((error) => of(new actions.UpdateWorkbookFail(error.message)))
                );
            })
        );
    });

    deleteWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.DeleteWorkbook),
            map((action: actions.UpdateWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.delete(payload.workbookId).pipe(
                    switchMap((res: any) => {
                        if (payload.courseWorkbookId) {
                            return [
                                new actions.DeleteWorkbookSuccess(payload.workbookId),
                                new RemoveWorkbookFromCourseViaLibrary(
                                    payload
                                )
                            ];
                        } else {
                            return [new actions.DeleteWorkbookSuccess(payload.workbookId)];
                        }
                    }),
                    catchError((error) => of(new actions.DeleteWorkbookFail(error.message)))
                );
            })
        );
    });

    removeWorkbookFromBuilder$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.RemoveWorkbookFromBuilder),
            map((action: actions.RemoveWorkbookFromBuilder) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.delete(payload.workbookId).pipe(
                    switchMap((res: any) => {
                            return [
                                new actions.RemoveWorkbookFromBuilderSuccess(payload.workbookId),
                                new RemoveWorkbookFromCourseSuccess(
                                    payload.courseWorkbookId
                                )
                            ];
                    }),
                    tap(() => {
                        this.router.navigate([`my-curriculum`]);

                    }),
                    catchError((error) => of(new actions.RemoveWorkbookFromBuilderFail(error.message)))
                );
            })
        );
    });

    copyWorkbooks$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.WorkbookActionTypes.CopyWorkbook),
            map((action: actions.CopyWorkbook) => action.payload),
            switchMap((payload: any) => {
                return this.workbooksService.copy(payload.id, payload.key, payload.changeName).pipe(
                    map((res: any) => {
                        return new actions.CopyWorkbookSuccess(res);
                    }),
                    catchError((error) => of(new actions.CopyWorkbookFail(error.message)))
                );
            })
        );
    });
}
