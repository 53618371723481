import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import { UtilityService } from '../../services/utility.service';
import { ListenerService } from '../../services/listener.service';
import { take } from 'rxjs';

@Component({
  selector: 'app-course-list-dialog',
  templateUrl: './html/course-list-dialog.html',
  styleUrls: [
    '../../../assets/css/main.css',
    '../../../assets/scss/fontawesome.scss',
    '../../../assets/scss/brands.scss',
    '../../../assets/scss/regular.scss',
    '../../../assets/scss/solid.scss',
  ]
})

export class CourseListDialogComponent implements OnInit {
  activityType: string;
  notAllowedAcitivitesSight: string[];
  acitivtySwitch: boolean;
  selectedPlan: string;
  constructor(
    public dialogRef: MatDialogRef<CourseListDialogComponent>,
    public route: ActivatedRoute,
    public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private utilityService: UtilityService,
    private listenerService: ListenerService
  ) {
    // override the route reuse strategy
    this.router.routeReuseStrategy.shouldReuseRoute = function() {
      return false;
    };

    this.listenerService.getPlan().pipe(take(1)).subscribe((plan) => {
      this.selectedPlan = plan;
    });
  }

  ngOnInit(): void {
    this.notAllowedAcitivitesSight = ['workbook-tiles', 'mini-tiles', 'lettercards'];
    const urlArray = document.URL.split('/');
    this.data.courseWorkbooksList = this.data.courseWorkbooksList.map(cw => {
      cw.link = `//activity/${urlArray[urlArray.length - 2]}/${cw._id}`;
      return cw;
    });
    this.activityType = urlArray[urlArray.length - 2];
    // this.checkActivity();
  }

  navigate(link: string, isPhoenetic, courseWorkbook, index) {
    if(this.selectedPlan === 'Demo v2' && index > 0) {
      return;
    }
    if(this.selectedPlan === 'Hero v2' && index >= 25) {
      return;
    }
    if (this.checkActivity(courseWorkbook)) {
      if (isPhoenetic || !this.notAllowedAcitivitesSight.includes(this.activityType)) {
        if (!courseWorkbook.nonsense || this.activityType !== 'spelling') {
          this.router.navigate([link]);
          this.dialogRef.close();
        }
      }
    }
  }

  checkActivity(courseWorkbook) {
    const isActive = this.utilityService.isCourseWorkbookActive(courseWorkbook);
    if (isActive) {
      if (this.activityType === 'spelling' && courseWorkbook.workbook.nonsense) { return false; } else if (this.activityType === 'passages' && !courseWorkbook.passages.length) {
        return false;
      } else if (this.activityType === 'sentences' && !courseWorkbook.sentences.length) {
        return false;
      } else if (this.activityType === 'phrases' && !courseWorkbook.phrases.length) {
        return false;
      } else if (this.activityType === 'pdf-viewer' && !courseWorkbook.files.length) {
        return false;
      } else {
        return true;
      }
    }

  }

  isWorkbookDisabled(courseWorkbook: any, index: number): boolean {
    const isDemoV2Disabled = this.selectedPlan === 'Demo v2' && index > 0;
    const isHeroV2Disabled = this.selectedPlan === 'Hero v2' && index >= 25;
    return !this.checkActivity(courseWorkbook) || isDemoV2Disabled || isHeroV2Disabled;
  }
  
  getWorkbookTitle(courseWorkbook: any, index: number): string {
    if (index === 0 && this.selectedPlan === 'Demo v2') {
      return 'My First Workbook';
    }
    return courseWorkbook.workbook?.name || courseWorkbook.workbook?.[0]?.name || '';
  }

  onCancel() {
    this.dialogRef.close();
  }
}
