import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Component, Inject, Input, OnInit, OnDestroy, ViewChild } from '@angular/core';
import {ActivityTypeService} from '../../core/services/activity-type.service';
import {debounceTime, distinctUntilChanged, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import { from, of, Subscription } from 'rxjs';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LessonPlansService} from '../../core/services/lesson-plans.service';
import {LessonPlansStepsService} from '../../core/services/lesson-plans-steps.service';
import {CourseWorkbookActivityService} from '../../core/services/course-workbook-activity.service';
import {CourseWorkbooksService} from '../../core/services/course-workbooks.service';
import {combineLatest, Subject} from 'rxjs';
import {SettingsService} from '../../core/services/new-settings.service';
import {UserService} from '../../core/services/user.service';
import { getCurrentSetting, selectActivityOrLessonFixStatus, selectActivityTypes, selectGetAllCourseWorkbook, selectGetLessonStep, selectGetWorkbookActivity } from '../../core/store';
import { GetLesson } from '../../core/store/lesson/lesson.actions';
import { GetWorkbookActivity, UpdateWorkbookActivity, UpdateWorkbookActivitySuccess } from '../../core/store/workbook-activity/workbook-activity.actions';
import { UpdateLessonStep } from '../../core/store/edit-lesson/edit-lesson.actions';
import { BlackboardInit, MiniTilesInit, UpdateInitDataCourseWorkbookActivitySuccess, WorkbookTilesInit } from '../../core/store/activity/activity.actions';
import { TilesUpdatingOptionEnum } from '../../core/components/course-workbook-edit/course-workbook-edit.component';
import { WordsService } from '../../core/services/words.service';
import { UtilityService } from '../../core/services/utility.service';
import { WorkbookActivityLessonStepService } from '../../core/services/workbook-activity-lesson-step.service';
import { ActivityService } from '../../core/services/activity.service';
import { SetActivityOrLessonFixStatus } from '../../core/store/user-interface/user-interface.actions';
import { AsyncPipe } from '@angular/common';
import { WorkbookActivityService } from '../../fabric-canvas/services/workbook-activity.service';

@Component({
  selector: 'app-course-workbook-edit-menu',
  templateUrl: 'text-box/course-workbook-edit-menu.html',
  styles: [
    `
      .close-btn {
        background: transparent !important;
      }
    `
  ],
  styleUrls: ['./../activity.scss',
    '../../assets/css/main.css',
    '../../assets/scss/fontawesome.scss',
    '../../assets/scss/brands.scss',
    '../../assets/scss/regular.scss',
    '../../assets/scss/solid.scss'
  ]
})

export class CourseWorkbookEditMenuComponent implements OnInit, OnDestroy {
  activityTypes: any[] = [];
  lessonPlanSteps: any[] = [];
  lessonPlanStep: any;
  courseWorkbooks: any[] = [];
  userSettings: any;
  courseWorkbookActivity: any;
  private unsubscribe$: Subject<void> = new Subject();
  private subscriptions = new Subscription();
  @ViewChild('courseWorkbookEdit') courseWorkbookEdit;
  private timerRef = null;
  private workbookActivitySubject$ = new Subject();
  workbookActivityStatus$ = this.store.select(selectActivityOrLessonFixStatus);
  isSaving = false


  constructor(
    public dialogRef: MatDialogRef<CourseWorkbookEditMenuComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private activityTypesService: ActivityTypeService,
    private lessonPlansService: LessonPlansService,
    private lessonPlansStepsService: LessonPlansStepsService,
    private courseWorkbookActivityService: CourseWorkbookActivityService,
    private courseWorkbooksService: CourseWorkbooksService,
    private userService: UserService,
    private settingsService: SettingsService,
    private store: Store,
    private router: Router,
    private wordsService: WordsService,
    private utiltiyService: UtilityService,
    private workbookActivityLessonStepService: WorkbookActivityLessonStepService,
    private activityService: ActivityService,
    protected async: AsyncPipe,
    private workbookActivityService: WorkbookActivityService
  ) {

  }

  ngOnInit(): void {
    this.subscriptions.add(
      this.store.select(getCurrentSetting)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(setting => {
        if (!setting) {
          return;
        }
        this.userSettings = JSON.parse(JSON.stringify(setting));
      })
      );

    const userId = JSON.parse(localStorage.profile).user_metadata.uid;
    this.subscriptions.add(
      combineLatest([
        this.store.select(selectGetLessonStep),
        this.store.select(selectGetAllCourseWorkbook),
        this.store.select(selectActivityTypes),
      ])
        .subscribe(([lessonPlanStep, courseWorkbooks, activityTypes]) => {

          if (lessonPlanStep) {
            this.lessonPlanSteps = [{...lessonPlanStep}];
            this.lessonPlanStep = lessonPlanStep;
          }

          this.courseWorkbooks = courseWorkbooks;
          this.activityTypes = activityTypes;
        })
    );

    this.subscriptions.add(
      this.store.select(selectGetWorkbookActivity)
        .pipe(take(1))
        .subscribe(courseWorkbookActivity => {
          this.courseWorkbookActivity = JSON.parse(JSON.stringify(courseWorkbookActivity));
        })
    );

    this.workbookActivitySubject$
      .pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
        tap(courseWorkbookActivity => {
          this.isSaving = true
          this.store.dispatch(new SetActivityOrLessonFixStatus('outdated'));
        }),
        debounceTime(1000),
        tap((courseWorkbookActivity: any) => {
          this.courseWorkbookEdit.tiles = courseWorkbookActivity.activityTypeId === 'miniTiles' ? courseWorkbookActivity.miniTiles : courseWorkbookActivity.tiles;
          // this.store.dispatch(new UpdateInitDataCourseWorkbookActivitySuccess(courseWorkbookActivity))
        }),
        switchMap(courseWorkbookActivity => {
          return this.courseWorkbookActivityService.update(courseWorkbookActivity)
            .pipe(
              tap((res) => {
                this.store.dispatch(new UpdateWorkbookActivitySuccess(courseWorkbookActivity));
                this.isSaving = false
              }),
              tap(() => this.store.dispatch(new UpdateInitDataCourseWorkbookActivitySuccess(courseWorkbookActivity))));
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe(res => {
        const splitedUrl = this.router.url.split('/');
        const activityType = splitedUrl[2];
        const courseWorkbookId = splitedUrl[3];
        const lessonPlanStepId = splitedUrl[5];
        const initData = {
          courseWorkbookId,
          lessonPlanStepId,
          userId: JSON.parse(localStorage.profile).user_metadata.uid,
          customerId: JSON.parse(localStorage.profile).user_metadata.cusID
        };

        console.log(initData);

        if (activityType === 'blackboard' || activityType === 'tileboard') {
          this.store.dispatch(new BlackboardInit(initData));
        } else if (activityType === 'workbook-tiles') {
          this.store.dispatch(new WorkbookTilesInit(initData));
        } else if (activityType === 'mini-tiles') {
          this.store.dispatch(new MiniTilesInit(initData));
        }
        this.store.dispatch(new SetActivityOrLessonFixStatus('updated'));
      });
  }

  ngOnDestroy() {
    if (!this.lessonPlanStep) {
      // this.courseWorkbookActivity.words = this.courseWorkbookActivity.words.splice(0, this.courseWorkbookActivity.numWords);
      // this.saveCourseWorkbookActivity(this.courseWorkbookActivity);
    }

    this.subscriptions.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  close() {
      // location.reload();
      this.dialogRef.close();
  }

  navigate(link: string) {

  }

  updateLessonPlanStep(lessonPlanSteps: any[]) {
    this.lessonPlanSteps = lessonPlanSteps;
  }

  updateCourseWorkbookActivity(courseWorkbookActivity) {
    if (!courseWorkbookActivity) { return; }
    // if (this.timerRef) {
    //   clearTimeout(this.timerRef);
    // }
    // this.timerRef = setTimeout(() => {
    const activityTypeId = courseWorkbookActivity?.activityTypeId || courseWorkbookActivity?.activityId;
      let oldData = JSON.parse(JSON.stringify(this.courseWorkbookActivity));
      let newData = JSON.parse(JSON.stringify(courseWorkbookActivity));

      oldData.tiles = oldData.tiles?.filter(tile => tile);
      newData.tiles = newData.tiles?.filter(tile => tile);

      if (courseWorkbookActivity.activityTypeId === 'miniTiles') {
        oldData.tiles = oldData.miniTiles?.filter(tile => tile);
        newData.tiles = newData.miniTiles?.filter(tile => tile);
      }

      // if (activityTypeId === 'workbookTiles') {
      //   oldData.tiles = oldData.tiles.filter(tile => tile)
      //   newData.tiles = newData.tiles.filter(tile => tile)
      // } else if (activityTypeId === 'miniTiles') {
      //   oldData.miniTiles = oldData.miniTiles.filter(tile => tile)
      //   newData.miniTiles = newData.miniTiles.filter(tile => tile)
      // }

      if (JSON.stringify(oldData) !== JSON.stringify(newData)) {
        const sort1 = oldData.words.map(item => item?.wordid).sort((a, b) => a - b);
        const sort2 = newData.words.map(item => item?.wordid).sort((a, b) => a - b);
        if (courseWorkbookActivity.tileUpdating === TilesUpdatingOptionEnum.ActiveWordsBased && JSON.stringify(sort1) !== JSON.stringify(sort2)) {
          // this.addTilesForActiveWords(courseWorkbookActivity);
          this.workbookActivityLessonStepService.applyActiveWordsTilesOption(newData)
            .pipe(take(1))
            .subscribe(tiles => {
              this.courseWorkbookEdit.tiles = tiles;
              // if (activityTypeId === 'workbookTiles') {
              //   newData.tiles = tiles;
              //   delete newData.miniTiles;
              // } else if (activityTypeId === 'miniTiles') {
              //   newData.miniTiles = tiles;
              //   delete newData.tiles;
              // }
              newData.tiles = tiles;
              if (courseWorkbookActivity.activityTypeId === 'miniTiles') {
                this.courseWorkbookEdit.miniTiles = tiles;
                newData.miniTiles = tiles;
              }
              this.saveCourseWorkbookActivity(newData);
            });
          return;
        } else {
          this.saveCourseWorkbookActivity(newData);
        }
      }
    // }, 1000);
  }

  saveCourseWorkbookActivity(courseWorkbookActivity: any) {
    const splitedUrl = this.router.url.split('/');
    const activityType = splitedUrl[2];
    this.courseWorkbookActivity = JSON.parse(JSON.stringify(courseWorkbookActivity));

    // const courseWorkbookId = splitedUrl[3];
    // const lessonPlanId = splitedUrl[4];
    // const lessonPlanStepId = splitedUrl[5];

    // this.store.dispatch(new UpdateInitDataCourseWorkbookActivitySuccess(courseWorkbookActivity))
    // this.store.dispatch(new UpdateWorkbookActivitySuccess(courseWorkbookActivity))

    this.workbookActivitySubject$.next(courseWorkbookActivity);

    // this.store.dispatch(new UpdateWorkbookActivity({
    //   courseWorkbookActivity,
    //   initData: {
    //     activityType,
    //     courseWorkbookId,
    //     lessonPlanStepId,
    //     userId: JSON.parse(localStorage.profile).user_metadata.uid,
    //     customerId: JSON.parse(localStorage.profile).user_metadata.cusID
    //   }
    // }));
    // this.workbookActivityLessonStepService.updateWorkbookActivity(courseWorkbookActivity, activityType)

    // if (
    //   courseWorkbookActivity.activityTypeId === "workbookTiles" ||
    //   courseWorkbookActivity.activityTypeId === "blackboard" ||
    //   courseWorkbookActivity.activityTypeId === "miniTiles"
    // ) {
    //   this.store.dispatch(new UpdateInitDataCourseWorkbookActivitySuccess(courseWorkbookActivity))
    // }

  }

  // addTilesForActiveWords(courseWorkbookActivity) {
  //   const userId = JSON.parse(localStorage.profile).user_metadata.uid;
  //   const backupTiles = courseWorkbookActivity.tiles;
  //   const ids = courseWorkbookActivity.words.map(word => word.wordid);
  //   this.wordsService
  //   .getTilesByWordIds(userId, ids)
  //   .pipe(
  //     take(1),
  //     switchMap((tiles: any[]) => {
  //       const generatedTiles =  [...new Set([...tiles.flat()])];
  //       const generatedTilesStr = JSON.stringify(generatedTiles.filter(tile => tile).sort((a, b) => a.localeCompare(b)))
  //       const oldTilesStr = JSON.stringify(backupTiles.filter(tile => tile).sort((a, b) => a.localeCompare(b)))
  //       if (generatedTilesStr !== oldTilesStr) {
  //         courseWorkbookActivity.tiles = backupTiles.filter(tile1=> {
  //           return generatedTiles.find(tile2 => tile1 === tile2)
  //         });
  //         const newAddTiles = generatedTiles.filter(n => !courseWorkbookActivity.tiles.includes(n))
  //         courseWorkbookActivity.tiles = [...courseWorkbookActivity.tiles, ...newAddTiles];
  //         if (courseWorkbookActivity.isTilesSorted) {
  //           // courseWorkbookActivity.tiles = courseWorkbookActivity.tiles.sort(((a, b) => a.localeCompare(b)))
  //           return this.utiltiyService
  //           .sortDroppedTiles(courseWorkbookActivity.tiles)
  //         } else {
  //           return of(courseWorkbookActivity.tiles)
  //         }
  //       }
  //     })
  //   )
  //   .subscribe(tiles => {
  //     this.courseWorkbookEdit.tiles = tiles;
  //     courseWorkbookActivity.tiles = tiles;
  //     this.saveCourseWorkbookActivity(courseWorkbookActivity)
  //   })
  // }
}
