// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
      :host {
        display: block;
        overflow: hidden;
      }
      .drag-disabled button {
        color: #dfdee5 !important;
        .dark-theme & {
          .mat-button-wrapper {
            color: gray !important;
          }
        }
      }

      .disabled {
        color: gray !important;

      }

    
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImNvdXJzZS13b3JrYm9vay1lZGl0LXdvcmRzLmNvbXBvbmVudC50cyJdLCJuYW1lcyI6W10sIm1hcHBpbmdzIjoiO01BQ007UUFDRSxjQUFjO1FBQ2QsZ0JBQWdCO01BQ2xCO01BQ0E7UUFDRSx5QkFBeUI7UUFDekI7VUFDRTtZQUNFLHNCQUFzQjtVQUN4QjtRQUNGO01BQ0Y7O01BRUE7UUFDRSxzQkFBc0I7O01BRXhCIiwiZmlsZSI6ImNvdXJzZS13b3JrYm9vay1lZGl0LXdvcmRzLmNvbXBvbmVudC50cyIsInNvdXJjZXNDb250ZW50IjpbIlxuICAgICAgOmhvc3Qge1xuICAgICAgICBkaXNwbGF5OiBibG9jaztcbiAgICAgICAgb3ZlcmZsb3c6IGhpZGRlbjtcbiAgICAgIH1cbiAgICAgIC5kcmFnLWRpc2FibGVkIGJ1dHRvbiB7XG4gICAgICAgIGNvbG9yOiAjZGZkZWU1ICFpbXBvcnRhbnQ7XG4gICAgICAgIC5kYXJrLXRoZW1lICYge1xuICAgICAgICAgIC5tYXQtYnV0dG9uLXdyYXBwZXIge1xuICAgICAgICAgICAgY29sb3I6IGdyYXkgIWltcG9ydGFudDtcbiAgICAgICAgICB9XG4gICAgICAgIH1cbiAgICAgIH1cblxuICAgICAgLmRpc2FibGVkIHtcbiAgICAgICAgY29sb3I6IGdyYXkgIWltcG9ydGFudDtcblxuICAgICAgfVxuXG4gICAgIl19 */`, "",{"version":3,"sources":["webpack://./src/core/components/course-workbook-edit/course-workbook-edit-words.component.ts"],"names":[],"mappings":";MACM;QACE,cAAc;QACd,gBAAgB;MAClB;MACA;QACE,yBAAyB;QACzB;UACE;YACE,sBAAsB;UACxB;QACF;MACF;;MAEA;QACE,sBAAsB;;MAExB;;;AAGN,g3BAAg3B","sourcesContent":["\n      :host {\n        display: block;\n        overflow: hidden;\n      }\n      .drag-disabled button {\n        color: #dfdee5 !important;\n        .dark-theme & {\n          .mat-button-wrapper {\n            color: gray !important;\n          }\n        }\n      }\n\n      .disabled {\n        color: gray !important;\n\n      }\n\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
