import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject, takeUntil } from 'rxjs';
import { selectGetAllSettings, getCurrentSetting } from '../../store';
import { DataService } from '../../services/data.service';
import { AsyncPipe } from '@angular/common';
import { MatSelect } from '@angular/material/select';

@Component({
  selector: 'app-settings-dropdown',
  templateUrl: './settings-dropdown.component.html',
  styleUrls: ['./settings-dropdown.component.scss']
})
export class SettingsDropdownComponent implements OnInit, OnDestroy {
  @ViewChild('settingsDropdown') settingsDropdown: MatSelect;
  
  @Input() currentSettingName: string;
  @Input() selectedPlan: string;
  @Input() activityName: string;
  @Output() settingChange = new EventEmitter<string>();
  @Output() editSetting = new EventEmitter<any>();

  
  private unsubscribe$: Subject<void> = new Subject();
  
  pencilImage: any;
  settingOptions$: Observable<any>;

  constructor(private store: Store, private dataService: DataService, private async: AsyncPipe) {}

  ngOnInit(): void {
    this.dataService._variable_images.pipe(takeUntil(this.unsubscribe$)).subscribe(path => {
      this.pencilImage = path + 'pencil.png';
    });

    this.store.select(getCurrentSetting).pipe(takeUntil(this.unsubscribe$)).subscribe((setting: any) => {
      if (!setting) {
        this.currentSettingName = 'Unnamed';
        return;
      }
      this.currentSettingName = setting.name;
    });

    this.settingOptions$ = this.store.select(selectGetAllSettings);
    setTimeout(() => {
      this.settingsDropdown.open();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onSettingChange(event: any): void {
    if(event.value === "button-to-settings") {
      this.settingChange.emit(event.value);
      return;
    }
    const settingsOptions = this.async.transform(this.settingOptions$);
    const settingName = event.value;

    if(this.currentSettingName === settingName) {
      return;
    } 

    this.currentSettingName = settingName;

    let setting = settingsOptions.find((setting) => setting.name === settingName);
    this.settingChange.emit(setting);
  }

  onEditSetting(option: any, event: MouseEvent): void {
    event.stopPropagation(); // Prevent the dropdown from closing
    this.editSetting.emit(option);
  }
}