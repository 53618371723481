// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .activity-canvas-settings {
  width: 100%;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbInNldHRpbmdzLWRyb3Bkb3duLmNvbXBvbmVudC5zY3NzIl0sIm5hbWVzIjpbXSwibWFwcGluZ3MiOiJBQUNJO0VBQ0ksV0FBQTtBQUFSIiwiZmlsZSI6InNldHRpbmdzLWRyb3Bkb3duLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiOmhvc3Qge1xuICAgIC5hY3Rpdml0eS1jYW52YXMtc2V0dGluZ3Mge1xuICAgICAgICB3aWR0aDogMTAwJTtcbiAgICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/core/components/settings-dropdown/settings-dropdown.component.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;AACA,oXAAoX","sourcesContent":[":host {\n    .activity-canvas-settings {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
