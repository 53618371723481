// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

:host ::ng-deep app-course-workbook-edit:first-child > mat-card {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
.dark-theme :host ::ng-deep app-course-workbook-edit:first-child > mat-card {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
:host ::ng-deep .whizzimo-mat-checkbox {
  margin-right: 24px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImVkaXQtbGVzc29uLXBsYW4uY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0EsdUJBQUE7RUFDQSxtQkFBQTtFQUNBLFlBQUE7RUFDQSxXQUFBO0FBQ0Y7O0FBR0U7RUFDRSwyQkFBQTtFQUNBLDRCQUFBO0FBQUo7QUFDSTtFQUNFLDJCQUFBO0VBQ0EsNEJBQUE7QUFDTjtBQUVFO0VBQ0Usa0JBQUE7QUFBSiIsImZpbGUiOiJlZGl0LWxlc3Nvbi1wbGFuLmNvbXBvbmVudC5zY3NzIiwic291cmNlc0NvbnRlbnQiOlsiLmxvYWRlciB7XG4gIGRpc3BsYXk6IGZsZXg7XG4gIGp1c3RpZnktY29udGVudDogY2VudGVyO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBoZWlnaHQ6IDEwMCU7XG4gIHdpZHRoOiAxMDAlO1xufVxuXG46aG9zdCA6Om5nLWRlZXAge1xuICBhcHAtY291cnNlLXdvcmtib29rLWVkaXQ6Zmlyc3QtY2hpbGQgPiBtYXQtY2FyZCB7XG4gICAgYm9yZGVyLXRvcC1sZWZ0LXJhZGl1czogOHB4O1xuICAgIGJvcmRlci10b3AtcmlnaHQtcmFkaXVzOiA4cHg7XG4gICAgLmRhcmstdGhlbWUgJiB7XG4gICAgICBib3JkZXItdG9wLWxlZnQtcmFkaXVzOiA4cHg7XG4gICAgICBib3JkZXItdG9wLXJpZ2h0LXJhZGl1czogOHB4O1xuICAgIH1cbiAgfVxuICAud2hpenppbW8tbWF0LWNoZWNrYm94IHtcbiAgICBtYXJnaW4tcmlnaHQ6IDI0cHg7XG4gIH1cbiAgLm1hdC1idXR0b24ge1xuICAgIC8vIG1hcmdpbi1yaWdodDogMjRweDtcbiAgfVxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/LessonPlans/Edit-Lesson-Plan/edit-lesson-plan.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;EACA,WAAA;AACF;;AAGE;EACE,2BAAA;EACA,4BAAA;AAAJ;AACI;EACE,2BAAA;EACA,4BAAA;AACN;AAEE;EACE,kBAAA;AAAJ;AACA,wjCAAwjC","sourcesContent":[".loader {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100%;\n  width: 100%;\n}\n\n:host ::ng-deep {\n  app-course-workbook-edit:first-child > mat-card {\n    border-top-left-radius: 8px;\n    border-top-right-radius: 8px;\n    .dark-theme & {\n      border-top-left-radius: 8px;\n      border-top-right-radius: 8px;\n    }\n  }\n  .whizzimo-mat-checkbox {\n    margin-right: 24px;\n  }\n  .mat-button {\n    // margin-right: 24px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
