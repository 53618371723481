// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@charset "UTF-8";
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url('background.3b1a81bd3a3ba9c0.jpg') no-repeat center fixed; /* pour anciens Chrome et Safari */
  background-size: cover; /* version standardisée */
}
.container .title {
  color: white;
  margin: 0;
  padding: 50px 20px;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhvbWUuY29tcG9uZW50LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUEsZ0JBQWdCO0FBQWhCO0VBQ0UsWUFBQTtFQUNBLGFBQUE7RUFDQSxtQkFBQTtFQUNBLHVCQUFBO0VBRUEseUVBQUEsRUFDQSxrQ0FBQTtFQUNBLHNCQUFBLEVBQUEseUJBQUE7QUFDRjtBQUNFO0VBQ0UsWUFBQTtFQUNBLFNBQUE7RUFDQSxrQkFBQTtBQUNKIiwiZmlsZSI6ImhvbWUuY29tcG9uZW50LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGFpbmVyIHtcbiAgaGVpZ2h0OiAxMDAlO1xuICBkaXNwbGF5OiBmbGV4O1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcblxuICBiYWNrZ3JvdW5kOiB1cmwoLi4vLi4vLi4vYXNzZXRzL2JhY2tncm91bmQuanBnKSBuby1yZXBlYXQgY2VudGVyIGZpeGVkO1xuICAtd2Via2l0LWJhY2tncm91bmQtc2l6ZTogY292ZXI7IC8qIHBvdXIgYW5jaWVucyBDaHJvbWUgZXQgU2FmYXJpICovXG4gIGJhY2tncm91bmQtc2l6ZTogY292ZXI7IC8qIHZlcnNpb24gc3RhbmRhcmRpc8OpZSAqL1xuXG4gIC50aXRsZSB7XG4gICAgY29sb3I6IHdoaXRlO1xuICAgIG1hcmdpbjowO1xuICAgIHBhZGRpbmc6NTBweCAyMHB4O1xuICB9XG5cbn1cbiJdfQ== */`, "",{"version":3,"sources":["webpack://./src/app/components/home/home.component.scss"],"names":[],"mappings":"AAAA,gBAAgB;AAAhB;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EAEA,yEAAA,EACA,kCAAA;EACA,sBAAA,EAAA,yBAAA;AACF;AACE;EACE,YAAA;EACA,SAAA;EACA,kBAAA;AACJ;AAAA,o6BAAo6B","sourcesContent":[".container {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n\n  background: url(../../../assets/background.jpg) no-repeat center fixed;\n  -webkit-background-size: cover; /* pour anciens Chrome et Safari */\n  background-size: cover; /* version standardisée */\n\n  .title {\n    color: white;\n    margin:0;\n    padding:50px 20px;\n  }\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
