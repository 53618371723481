import { AmplitudeService } from '../../services/amplitude.service';
import * as workbookActions from './workbook.actions';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';

export interface State extends EntityState<any> {
    selectedId: string;
    id: string;
    pending: boolean;
    error: any;
    succeeded: boolean;
    all: boolean;
    workbook: any;
    libraryWorkbooks: any;
}

export const adapter: EntityAdapter<string> = createEntityAdapter({
    selectId: (course: any) => course._id
});
const initialState: State = adapter.getInitialState({
    selectedId: null,
    id: null,
    pending: false,
    error: null,
    succeeded: false,
    all: false,
    workbook: null,
    libraryWorkbooks: null
});


export function reducer(state = initialState, action: workbookActions.WorkbookActions): State {
    switch (action.type) {
        case workbookActions.WorkbookActionTypes.CreateWorkbook: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        
        case workbookActions.WorkbookActionTypes.CreateWorkbookSuccess: {
            const updatedState = adapter.addOne(action.payload, state);
            const totalEntities = updatedState.ids.length;
        
            AmplitudeService.identifyUser({
                workbookCount: totalEntities
            });

            (<any>window).Intercom("update", {
                workbookCount: totalEntities
            });
        
            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case workbookActions.WorkbookActionTypes.UpdateWorkbook: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }

        case workbookActions.WorkbookActionTypes.UpdateWorkbookSuccess: {
            return {
                ...adapter.updateOne({id: action.payload._id, changes: action.payload.data}, state),
                pending: false,
                succeeded: true,
                error: null
            };

        }
        case workbookActions.WorkbookActionTypes.DeleteWorkbook: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null,
            };
        }
        case workbookActions.WorkbookActionTypes.CopyWorkbook: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.CopyWorkbookSuccess: {
            const updatedState = adapter.addOne(action.payload, state);
            const totalEntities = updatedState.ids.length;
        
            AmplitudeService.identifyUser({
                workbookCount: totalEntities
            });

            (<any>window).Intercom("update", {
                workbookCount: totalEntities
            });

            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.DeleteWorkbookSuccess: {
            const updatedState = adapter.removeOne(action.payload, state);
            const totalEntities = updatedState.ids.length;
        
            AmplitudeService.identifyUser({
                workbookCount: totalEntities
            });

            (<any>window).Intercom("update", {
                workbookCount: totalEntities
            });
            
            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case workbookActions.WorkbookActionTypes.DeleteWorkbookFail: {
            return {
                ...state,
                pending: false,
                succeeded: false,
                error: action.payload,
            };
        }
        case workbookActions.WorkbookActionTypes.GetMyLibraryWorkbooks: {
            return {
                ...state,
                selectedId: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.GetMyLibraryWorkbooksSuccess: {
            const courses = action.payload.slice();
            courses.sort((a, b) => a.order !== b.order ? a.order < b.order ? -1 : 1 : 0);

            const updatedState = adapter.addMany(courses, state);
            const totalEntities = updatedState.ids.length;

            AmplitudeService.identifyUser({
                workbookCount: totalEntities
            });

            (<any>window).Intercom("update", {
                workbookCount: totalEntities
            });

            return {
                ...updatedState,
                succeeded: false,
                pending: false,
                all: true,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.GetMyLibraryWorkbooksFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                selectedId: null,
                error: action.payload
            };
        }
        case workbookActions.WorkbookActionTypes.ClearWorkbooks: {
            return {
                ...adapter.removeAll(state),
                succeeded: false,
                pending: false,
                error: null
            };
        }

        case workbookActions.WorkbookActionTypes.GetWorkbook: {
            return {
                ...state,
                id: action.payload,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.GetWorkbookSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                id: state.id,
                workbook: action.payload,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.GetWorkbookFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                id: null,
                error: action.payload
            };
        }

        case workbookActions.WorkbookActionTypes.GetWhizzimoLibraryWorkbooks: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.GetWhizzimoLibraryWorkbooksSuccess: {
            return {
                ...state,
                succeeded: true,
                pending: false,
                libraryWorkbooks: action.payload,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.GetWhizzimoLibraryWorkbooksFail: {
            return {
                ...state,
                succeeded: false,
                pending: false,
                error: action.payload
            };
        }
        case workbookActions.WorkbookActionTypes.RemoveWorkbookFromBuilder: {
            return {
                ...state,
                pending: true,
                succeeded: false,
                error: null
            };
        }
        case workbookActions.WorkbookActionTypes.RemoveWorkbookFromBuilderSuccess: {
            const updatedState = adapter.removeOne(action.payload, state);
            const totalEntities = updatedState.ids.length;
        
            AmplitudeService.identifyUser({
                workbookCount: totalEntities
            });

            (<any>window).Intercom("update", {
                workbookCount: totalEntities
            });

            return {
                ...updatedState,
                pending: false,
                succeeded: true,
                error: null
            };
        }

        case workbookActions.WorkbookActionTypes.StoreWorkbookItem: {
            return {
                ...state,
                succeeded: false,
                pending: true,
                error: null,
            };
        }
        case workbookActions.WorkbookActionTypes.StoreWorkbookItemSuccess: {
            const updatedState = adapter.addOne(action.payload, state);
            const totalEntities = updatedState.ids.length;
        
            AmplitudeService.identifyUser({
                workbookCount: totalEntities
            });

            (<any>window).Intercom("update", {
                workbookCount: totalEntities
            });

            return {
                ...updatedState,
                succeeded: true,
                pending: false,
                error: null,
            };
        }


        default:
            return state;
    }
}

export const {
    selectIds: SelectAllIds,
    selectEntities: SelectAllEntities,
    selectAll: SelectAllWorkbooks,
} = adapter.getSelectors();

export const getPending = (state: State) => state.pending;
export const getSucceeded = (state: State) => state.succeeded;
export const getAllSucceeded = (state: State) => state.all;
export const getError = (state: State) => state.error;
export const getLibraryWorkbooks = (state: State) => state.libraryWorkbooks;
export const getSelectedWorkbooks = (state: State) => {
    return state.entities[state.selectedId];
};
export const getWorkbook = (state: State) => state.workbook;
export const getAllWorkbooks = (state: State) => {
    let courses = SelectAllWorkbooks(state);
    return [...courses];
};
