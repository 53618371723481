// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0px 17px;
  max-width: 200px;
}
:host .button span {
  font-size: 16px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbImhlYWRlci1hY3Rpdml0aWVzLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBSUE7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSx1QkFBQTtFQUNBLGlCQUFBO0VBRUEsZ0JBQUE7QUFKRjtBQU1FO0VBQ0UsZUFBQTtFQUNBLG1CQUFBO0VBQ0EsdUJBQUE7RUFDQSxnQkFBQTtBQUpKIiwiZmlsZSI6ImhlYWRlci1hY3Rpdml0aWVzLnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIvLyBAaW1wb3J0IFwiLi4vLi4vLi4vc3R5bGVzLnNjc3NcIjtcbi8vIEBpbXBvcnQgXCIuLi8uLi8uLi92YXJpYWJsZS9fdmFyaWFibGUuc2Nzc1wiO1xuLy8gQGltcG9ydCBcIi4uLy4uLy4uL3ZhcmlhYmxlL192YXJpYWJsZS12MS5zY3NzXCI7XG5cbjpob3N0IC5idXR0b24ge1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgcGFkZGluZzogMHB4IDE3cHg7XG5cbiAgbWF4LXdpZHRoOiAyMDBweDtcblxuICBzcGFuIHtcbiAgICBmb250LXNpemU6IDE2cHg7XG4gICAgd2hpdGUtc3BhY2U6IG5vd3JhcDtcbiAgICB0ZXh0LW92ZXJmbG93OiBlbGxpcHNpcztcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xuICB9XG5cblxufVxuIl19 */`, "",{"version":3,"sources":["webpack://./src/core/components/header/header-activities.scss"],"names":[],"mappings":"AAIA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,iBAAA;EAEA,gBAAA;AAJF;AAME;EACE,eAAA;EACA,mBAAA;EACA,uBAAA;EACA,gBAAA;AAJJ;AACA,o5BAAo5B","sourcesContent":["// @import \"../../../styles.scss\";\n// @import \"../../../variable/_variable.scss\";\n// @import \"../../../variable/_variable-v1.scss\";\n\n:host .button {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  padding: 0px 17px;\n\n  max-width: 200px;\n\n  span {\n    font-size: 16px;\n    white-space: nowrap;\n    text-overflow: ellipsis;\n    overflow: hidden;\n  }\n\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
