// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container button {
  margin: 2px;
  border-radius: 15px;
  background-color: #866dff;
  width: 20vw;
}

.exit-button {
  display: flex;
  justify-content: flex-end;
}

.exit-button span {
  cursor: pointer;
  color: white;
}

::ng-deep .mat-bottom-sheet-container {
  overflow: hidden;
}
/*# sourceMappingURL=data:application/json;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VzIjpbIndvcmQtYm90dG9tLXNoZWV0LnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRSxhQUFBO0VBQ0Esc0JBQUE7RUFDQSxtQkFBQTtBQUNGOztBQUNBO0VBRUUsV0FBQTtFQUNBLG1CQUFBO0VBQ0EseUJBQUE7RUFFQSxXQUFBO0FBQUY7O0FBRUE7RUFDRSxhQUFBO0VBQ0EseUJBQUE7QUFDRjs7QUFDQTtFQUNFLGVBQUE7RUFDQSxZQUFBO0FBRUY7O0FBQ0U7RUFDRSxnQkFBQTtBQUVKIiwiZmlsZSI6IndvcmQtYm90dG9tLXNoZWV0LnNjc3MiLCJzb3VyY2VzQ29udGVudCI6WyIuY29udGFpbmVye1xuICBkaXNwbGF5OiBmbGV4O1xuICBmbGV4LWRpcmVjdGlvbjogY29sdW1uO1xuICBhbGlnbi1pdGVtczogY2VudGVyO1xufVxuLmNvbnRhaW5lciBidXR0b257XG5cbiAgbWFyZ2luOiAycHg7XG4gIGJvcmRlci1yYWRpdXM6IDE1cHg7XG4gIGJhY2tncm91bmQtY29sb3I6ICM4NjZkZmY7XG4gIC8vIGJvcmRlcjogM3B4IHNvbGlkIGJsYWNrO1xuICB3aWR0aDogMjB2dztcbn1cbi5leGl0LWJ1dHRvbntcbiAgZGlzcGxheTogZmxleDtcbiAganVzdGlmeS1jb250ZW50OiBmbGV4LWVuZDtcbn1cbi5leGl0LWJ1dHRvbiBzcGFue1xuICBjdXJzb3I6IHBvaW50ZXI7XG4gIGNvbG9yOiB3aGl0ZTtcbn1cbjo6bmctZGVlcCB7XG4gIC5tYXQtYm90dG9tLXNoZWV0LWNvbnRhaW5lcntcbiAgICBvdmVyZmxvdzogaGlkZGVuO1xuICB9XG59Il19 */`, "",{"version":3,"sources":["webpack://./src/core/components/workbooks/workbook-builder/word-bottom-sheet/word-bottom-sheet.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACF;;AACA;EAEE,WAAA;EACA,mBAAA;EACA,yBAAA;EAEA,WAAA;AAAF;;AAEA;EACE,aAAA;EACA,yBAAA;AACF;;AACA;EACE,eAAA;EACA,YAAA;AAEF;;AACE;EACE,gBAAA;AAEJ;AACA,wgCAAwgC","sourcesContent":[".container{\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n.container button{\n\n  margin: 2px;\n  border-radius: 15px;\n  background-color: #866dff;\n  // border: 3px solid black;\n  width: 20vw;\n}\n.exit-button{\n  display: flex;\n  justify-content: flex-end;\n}\n.exit-button span{\n  cursor: pointer;\n  color: white;\n}\n::ng-deep {\n  .mat-bottom-sheet-container{\n    overflow: hidden;\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
