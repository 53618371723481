import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of,  Observable} from 'rxjs';
import { catchError, map, switchMap, concatMap } from 'rxjs/operators';
import { SubscriptionService } from '../../services/subscription.service';
import { GetAllCourse } from '../courses/courses.actions';
import { SetCurriculumCourse } from '../set-course/set-course.actions';
import * as actions from './subscriptions.actions';
import { AmplitudeService } from '../../services/amplitude.service';

@Injectable()

export class SubscriptionEffect {
    constructor(
        private actions$: Actions,
        private store$: Store,
        private Subscriptionervice: SubscriptionService,
        private amplitudeService: AmplitudeService,
    ) {}
    getSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubscriptionActionTypes.GetSubscription),
            map((action: actions.GetSubscription) => action.payload),
            switchMap((payload: any) => {
                return this.Subscriptionervice.get(payload).pipe(
                    map((res: string) => new actions.GetSubscriptionSuccess(res)),
                    catchError((error) => of(new actions.GetSubscriptionFail(error.message)))
                );
            })
        );
    });

    getAllSubscribedCourses$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubscriptionActionTypes.GetAllSubscribedCourses),
            map((action: actions.GetAllSubscribedCourses) => action.payload),
            switchMap((payload: any) => {
                return this.Subscriptionervice.getMany(payload).pipe(
                    map((res: any) => {
                        const result = res.sort((a, b) => {
                            return a.order - b.order;
                        });

                        const hasBarton = !!result.find((item: any) => item.courseName.includes('Barton'));
                        const hasDyslexiaCenterOfUtah = !!result.find((item: any) => item.courseName.startsWith('MOR'));

                        // Give me the name of the Barton 
                        AmplitudeService.identifyUser({
                            hasBartonCourses: hasBarton,
                            hasDyslexiaCenterOfUtahCourses: hasDyslexiaCenterOfUtah,
                            subscriptionCourses: result.map((course: any) => course.courseName),
                        });
                        AmplitudeService.identifyUser({
                            subscribedCourseCount: result.length,
                        });
                        return result;
                    }),
                    switchMap((res: any) => [
                        // new actions.ClearSubscriptions(),
                        // new actions.GetAllSubscriptionsSuccess(res.map(course => {
                        //     course.courseName += ' - Subscribed';
                        //     return course;
                        //   })
                        // )
                        new actions.GetAllSubscribedCoursesSuccess(res),
                    ]
                ),
                    catchError((error) => of(new actions.GetAllSubscribedCoursesFail(error.message)))
                );
            })
        );
    });

    createSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubscriptionActionTypes.CreateSubscription),
            map((action: actions.CreateSubscription) => action.payload),
            switchMap((payload: any) => {
                const userId = JSON.parse(localStorage.profile).user_metadata.uid;

                return this.Subscriptionervice.create(payload).pipe(
                    map((res: string) => new actions.GetAllSubscribedCourses(userId)),
                    catchError((error) => of(new actions.CreateSubscriptionFail(error.message)))
                );
            })
        );
    });

    deleteSubscription$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(actions.SubscriptionActionTypes.DeleteSubscription),
            map((action: actions.DeleteSubscription) => action.payload),
            switchMap((payload: any) => {
                const userId = JSON.parse(localStorage.profile).user_metadata.uid;
                return this.Subscriptionervice.delete(payload).pipe(
                    map((res: string) => new actions.GetAllSubscribedCourses(userId)),
                    catchError((error) => of(new actions.DeleteSubscriptionFail(error.message)))

                );
            })
        );
    });

}
